import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Image } from 'react-bootstrap';
import validator from 'validator';
import { app } from '../../services/firebase';
import { useNavigate, Link } from 'react-router-dom';
import '../../styles/signup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
//import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile, sendEmailVerification } from 'firebase/auth';
import {GoogleAuthProvider, signInWithPopup, updateProfile, sendEmailVerification } from 'firebase/auth';
import { useAuthContext } from '../../services/AuthContext';
import api from '../../services/ApiConfig';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [town, setTown] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [stateProvince, setStateProvince] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    // Make a isAdmin role check so users of the app cannot make changed to a company's account

    const { createUser } = useAuthContext();
    const navigate = useNavigate();
    

    const handleSignup = async (e) => {
        e.preventDefault();
        setDisableButton(true);

        const errors = validateUser().concat(validateCompany());

        setErrorMessages(errors);

        try {

            if (errors.length != 0) {
                console.error(errors);
                return;
            }

            const userCredential = await createUser(email, password);
            await sendEmailVerification(userCredential.user);
            setSuccessMessage('A verification email has been sent to your email address. Please verify your email address.');
            
            const res = api.post('/registration', {
                company: company,
                address: address,
                town: town,
                postalCode: postalCode,
                country: country,
                stateProvince: stateProvince,
                phone: phone,
            }
            ).then((res) => {
                if (res.status === 200)
                    navigate('/home');
                else
                    setErrorMessages("Something went wrong with the registration. Please refresh the page and try again.");

            }
            ).catch((err) => {
                console.log(err);
            });

            
            console.log('post- createUser');

/*
            const auth = app.auth();

            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            const user = userCredential.user;

            sendEmailVerification(user)
                .then(() => {
                    setSuccessMessage('A verification email has been sent to your email address. Please verify your email address.');
                });

            // Post the company to the database 
            const res = api.post('/registration', {

                company: company,
                address: address,
                town: town,
                postalCode: postalCode,
                country: country,
                stateProvince: stateProvince,
                phone: phone,
            }
            ).then((res) => {
                if (res.status === 200)
                    setRedirectToLogin(true);
                else
                    setErrorMessages("Something went wrong with the registration. Please refresh the page and try again.");

            }
            ).catch((err) => {
                console.log(err);
            }
            );

*/
        } catch (error) {
            console.error('Error signing up:', error.message);
            setErrorMessages([error.message]);
        } finally{
            // Still gets called even in an Navigate or return above
            console.log('in finally');
            setDisableButton(false);
        }

    };

    const validateUser = () => {
        const errors = [];

        if (!validator.isEmail(email)) {
            errors.push('Invalid email address.');
        }

        if (!validator.isMobilePhone(phone, 'any')) {
            errors.push('Invalid phone number.');
        }

        if (!validator.isLength(password, { min: 6 })) {
            errors.push('Password must be at least 6 characters.');
        }

        if (password !== passwordConfirmation) {
            errors.push('Passwords do not match.');
        }

        return errors;
    };

    const validateCompany = () => {
        const errors = [];

        if (!company) {
            errors.push('Please enter a company name.');
        }

        if (!address) {
            errors.push('Please enter an address.');
        }

        if (!town) {
            errors.push('Please enter a town.');
        }

        if (!country) {
            errors.push('Please select a country.');
        }

        if (country === 'Canada' && !stateProvince) {
            errors.push('Please select a province.');
        }

        if (country === 'US' && !stateProvince) {
            errors.push('Please select a state.');
        }

        if (country === 'US' && !validator.isPostalCode(postalCode, 'US')) {
            errors.push('Invalid US zip code.');
        }

        if (country === 'Canada' && !validator.isPostalCode(postalCode, 'CA')) {
            errors.push('Invalid Canadian postal code.');
        }

        return errors;
    };

    const handleGoogleSignup = async () => {
        setDisableButton(true);
        const errors = validateCompany();


        setErrorMessages(errors);
        if (errors.length === 0) {

            const auth = app.auth();
            const provider = new GoogleAuthProvider();
            try {
                const result = await signInWithPopup(auth, provider);
                console.log('Google Sign-In Result:', result);
                // Handle the result, which includes the user information
                setRedirectToLogin(true);

            } catch (error) {
                setDisableButton(false);

            }
        }
        setDisableButton(false);
    };

   // if (redirectToLogin) {
     //   return <Navigate to="/login" />;
    //}    }


    return (
        <Container fluid className="full-height">
            <Row className="full-height">
                <Col className="left-column">
                    <img src="/images/field.jpg" alt="Field" />
                </Col>
                <Col className="right-column">
                    <div className="centered-content px-lg-5">                        <div className="text-center mb-4">
                        <img src="/images/logos/harvyst.svg" alt="Logo" className="img-fluid" width={250} height={35} />
                    </div>

                        {errorMessages.length > 0 && (
                            <Alert variant="danger">
                                {errorMessages.map((message, index) => (
                                    <p key={index}>{message}</p>
                                ))}
                            </Alert>
                        )}
                        <Form>
                            <Row><h3 className="mt-2">Your Information</h3>

                                <Form.Group className="mt-2" controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        isInvalid={email && !validator.isEmail(email)}
                                        autoComplete='email'

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email address.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        isInvalid={password.length > 0 && password.length < 6}
                                        autoComplete='new-password'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Password must be at least 6 characters.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicPasswordConfirmation">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm password"
                                        value={passwordConfirmation}
                                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                                        isInvalid={passwordConfirmation && password !== passwordConfirmation}
                                        autoComplete='new-password'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Passwords do not match.
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Row>
                                <h3 className="mt-2">Company Information</h3>
                                <Form.Group className="mt-2" controlId="formBasicCompany">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your company"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                        autoComplete='organization-title'
                                    />
                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicPhone">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter your phone number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        isInvalid={phone && !validator.isMobilePhone(phone, 'any')}
                                        autoComplete='tel'
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid phone number.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicAddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your address"
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        autoComplete='street-address'
                                    />
                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicTown">
                                    <Form.Label>Town</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your town"
                                        value={town}
                                        onChange={(e) => setTown(e.target.value)}
                                        autoComplete='city'
                                    />
                                </Form.Group>


                                <Form.Group className="mt-2" controlId="formBasicCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={country}
                                        autoComplete='country'
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        <option value="">Select a country</option>
                                        <option value="CA">Canada</option>
                                        <option value="US">US</option>

                                    </Form.Control>

                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicPostalCode">
                                    <Form.Label>{country === 'US' ? 'Zip Code' : 'Postal Code'}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete='postal-code'
                                        placeholder={`Enter your ${country === 'US' ? 'zip code' : 'postal code'}`}
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        isInvalid={postalCode && !validator.isPostalCode(postalCode, country === 'US' ? 'US' : 'CA')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {country === 'US'
                                            ? 'Please enter a valid US zip code.'
                                            : 'Please enter a valid Canadian postal code.'}
                                    </Form.Control.Feedback>

                                </Form.Group>

                                <Form.Group className="mt-2" controlId="formBasicStateProvince">
                                    <Form.Label>{country === 'US' ? 'State' : 'Province'}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={stateProvince}
                                        onChange={(e) => setStateProvince(e.target.value)}
                                    >
                                        <option value="">Select a {country === 'US' ? 'state' : 'province'}</option>
                                        {/* Populate options based on selected country */}
                                        {country === 'CA' && (
                                            <optgroup label="Canada">
                                                <option value="Ontario">Ontario</option>
                                                <option value="Quebec">Quebec</option>
                                                <option value="Nova Scotia">Nova Scotia</option>
                                                <option value="New Brunswick">New Brunswick</option>
                                                <option value="Manitoba">Manitoba</option>
                                                <option value="British Columbia">British Columbia</option>
                                                <option value="Prince Edward Island">Prince Edward Island</option>
                                                <option value="Saskatchewan">Saskatchewan</option>
                                                <option value="Alberta">Alberta</option>
                                                <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                                <option value="Northwest Territories">Northwest Territories</option>

                                            </optgroup>
                                        )}
                                        {country === 'US' && (
                                            <optgroup label="US">
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Hampshire">New Hampshire</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New Mexico">New Mexico</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="North Dakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="Rhode Island">Rhode Island</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="South Dakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="West Virginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </optgroup>
                                        )}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a {country === 'US' ? 'state' : 'province'}.
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>
                            <Link to="/login">
                                <Button variant="primary" className="mt-2  harvyst-green">
                                    <FontAwesomeIcon icon={faArrowLeft} />  Back to Login
                                </Button>
                            </Link>
                            <Button
                                variant="primary"
                                type="submit"
                                block
                                className='mt-2 mx-2'
                                disabled={disableButton}
                                onClick={handleSignup}
                            >
                                {disableButton ? (
                                    <>
                                        Signing up...
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </>
                                ) : (
                                    'Sign up'
                                )}
                            </Button>
                            <p className='text-success'>{successMessage}</p>

                        </Form>

                        {/* <hr />
                        <div className="text-center">
                            <p>Sign up with partners</p>
                            <div className="google-login">
                                <Button variant="light" className="login-provider-button px-2 border-primary" hover onClick={handleGoogleSignup}>
                                    <img src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" height={35} alt="google icon" />
                                    <span> Signup with Google</span>
                                </Button>
                            </div>
                        </div> */}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Signup;
