import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

const Notification = ({ body, timestamp, link, index, onClose }) => {
    return (
        <Card>
            <Card.Body>
                <div className="remove-btn" onClick={() => onClose(index)}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                <Card.Title>Notification</Card.Title>
                <Card.Text>{body}</Card.Text>
                <Card.Text className="text-muted">{timestamp}</Card.Text>

                <Card.Link href={link} className='btn btn-primary'>View<FontAwesomeIcon className="mx-2" icon={faArrowCircleRight} /></Card.Link>
            </Card.Body>
        </Card>
    );
};


const NotificationTray = ({ show, notifications, handleClose, removeNotification, darkMode }) => {
    useEffect(() => {
        const handleBodyClick = (event) => {
            if (!event.target.closest('.notification-tray')) {
                handleClose();
            }
        };
        document.body.addEventListener('click', handleBodyClick);

        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [handleClose]);

    return (
        <div className={`notification-tray ${show ? 'active' : ''}`}>
            {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                    <Notification
                        key={index}
                        body={notification.body}
                        timestamp={notification.timestamp}
                        index={index}
                        link={notification.link}
                        onClose={removeNotification} // Pass the removeNotification function as onClose
                    />
                ))
            ) : (
                <div className="no-notifications">No new notifications</div>
            )}
        </div>
    );
};


export default NotificationTray;
