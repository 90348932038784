import React, { useState, useEffect } from 'react';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import '../../styles/inventory.css';
import InventoryEntry from './InventoryEntry';

import { useDataContext } from '../../services/DataContext';
//import SubscribersModal from './SubscriberModal';
// import api from 'api';
import api from '../../services/ApiConfig';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../components/LoadingSpinner';

const Inventory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showGraph, setShowGraph] = useState(false);
    const [ formattedFullInventory, setFormattedFullInventory ] = useState([]);
    const [ formattedSumInventory, setFormattedSumInventory] = useState([])

    const { commodity_data, company_data } = useDataContext();
    
    const darkMode = false;

    // Function to fetch news entries from the Flask API
    const fetchInventoryData = async () => {
        setIsLoading(true);
        try {
            
            const res = await api.get('/inventory');

            if (Array.isArray(res.data.data)) {
                const fullInv = res.data.data.map((entry) => {
                    const attributes = entry.attributes;
                    const commodity = commodity_data.find((c) => c.commodity_id === attributes.commodity_id);
                    const company = company_data.find((c) => c.company_id === attributes.company_id);
    
                    return {
                        company_name: company?company.company_name:'UNK',
                        company_id : company?company.company_id:-1,
                        commodity_name: commodity.commodity,
                        commodity_id : commodity.commodity_id,
                        total: attributes.total,
                    };
                });
                setFormattedFullInventory(fullInv);
            }
        } catch (error) {
            console.error('Error fetching inventory', error);
        }finally{
            setIsLoading(false);
        }
    };


    useEffect(() => {
        
        console.log('Full inventory is ', formattedFullInventory)
        const sumInv = []
        console.log('Doing summation of the inventory');
        for(let i = 0; i < formattedFullInventory.length; i++){
            let commodity = commodity_data.find((c) => c.commodity_id === formattedFullInventory[i].commodity_id);
            let amt = Number(formattedFullInventory[i].total);
            
            let isElevator = true
            if(formattedFullInventory[i].company_id > 0){
                isElevator = false;
            }

            let foundEntry = sumInv.find(o => o.commodity_id == formattedFullInventory[i].commodity_id);
            if(foundEntry){
                if(isElevator){
                    foundEntry.elevator += Number(amt);
                }else{
                    foundEntry.stored += Number(amt);
                }
                foundEntry.total += Number(amt);

            }else{
                let x = {commodity_name:commodity.commodity, commodity_id:commodity.commodity_id, elevator: 0, stored: 0, total: amt}
                if(isElevator){
                    x.elevator = Number(amt);
                }else{
                    x.stored = Number(amt);
                }
                sumInv.push(x)
            }
        }
        console.log(sumInv)
        setFormattedSumInventory(sumInv);
        
    }, [formattedFullInventory]);

    // Fetch news entries from the Flask API when the component mounts
    useEffect(() => {
        fetchInventoryData();
    }, []);

    return (
        <div>
            <div className='mb-2'>
                <h1>Inventory</h1>
                <h6 className={darkMode ? ' ' : 'text-muted'}>
                    Keep track of your Inventory.
                </h6>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-2">

                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={<Tooltip className='button-tooltip'>Show Graph</Tooltip>}
                            >
                                <Form.Check
                                    className=""
                                    type="switch"
                                    label="Show Graphs"
                                    id="graph-switch"
                                    checked={showGraph}
                                    onChange={() => setShowGraph(!showGraph)}
                                />
                            </OverlayTrigger>
                        </div>

                    </div >
                </div >
            </div >
            <Table hover variant={darkMode ? 'dark' : ''}>
                <thead>
                    <tr>
                    <th className="col-md-4">Commodity</th>
                    <th className="col-md-4">Elevator</th>
                        <th className="col-md-5">Stored</th>
                        <th className="col-md-5">Total</th>
                        <th className="col-md-1"></th>
                    </tr>
                </thead>

                <tbody>
                    {formattedSumInventory.length === 0 ? (
                        <tr>
                            <td colSpan={4} className='text-center'>
                                {isLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    "No inventory yet!"
                                )}
                            </td>
                        </tr>
                    ) : (
                        formattedSumInventory.map((inv) => {

                                return (
                                    <InventoryEntry 
                                        inv={inv}
                                    />
                                );
                            return null; // Exclude inactive news
                        })
                    )}
                </tbody>

            </Table>


        </div >
    );
};
export default Inventory;