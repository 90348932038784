import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import './styles/custom.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from './services/ToastContext';
import Landing from './pages/landing/landing';

console.log('HERE', process.env.NODE_ENV);

const root = ReactDOM.createRoot(document.getElementById('root'));

const bLandingPageOnly = true;

if(bLandingPageOnly){
  root.render(
    <Landing></Landing>
  );
}else{

  root.render(
    <BrowserRouter>    
    <ToastProvider>
      <App />
    </ToastProvider>
  </BrowserRouter>
  );

/*
<React.StrictMode>
<BrowserRouter>
  <ToastProvider>
    <App />
  </ToastProvider>
</BrowserRouter>
</React.StrictMode>
*/

}

/*
root.render(
  <BrowserRouter>    
  <ToastProvider>
    <App />
  </ToastProvider>
</BrowserRouter>
);
*/
/*
<React.StrictMode>
<BrowserRouter>
  <ToastProvider>
    <App />
  </ToastProvider>
</BrowserRouter>
</React.StrictMode>
*/
