import React from 'react';
import { Container, Table, Form, Button, OverlayTrigger, Tooltip, Card, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeightHanging } from '@fortawesome/free-solid-svg-icons';




export const TextInputWithSideLabel = ({ label, value, onChange, onBlur, placeholder, isDisabled = false, type = 'text', autoFocus = false, tabOrder = 0 }) => {
    return (
        <InputGroup controlId={label} className='mt-2'>
            <InputGroup.Text style={{ minWidth: '100px' }}>{label}</InputGroup.Text>
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                autoFocus={autoFocus}
                tabIndex={tabOrder}
            />
        </InputGroup>


    );
};

export const TextWithLabel = ({ label, value, onChange, onBlur, placeholder, isDisabled = false, type = 'text', autoFocus = false, tabOrder = 0 }) => {
    return (
        <Form.Group controlId="ticketDate" className='mt-2'>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                autoFocus={autoFocus}
                tabIndex={tabOrder}
            />
        </Form.Group>

    );
};

export const TextAreaSideLabel = ({ label, value, onChange, onBlur, placeholder, isDisabled = false, type = 'text', autoFocus = false, tabOrder = 0 }) => {
    return (
        <Form.Group controlId="ticketDate" className='mt-2'>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as="textarea"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                autoFocus={autoFocus}
                tabIndex={tabOrder}
            />
        </Form.Group>

    );
};

export const SelectWithLabel = ({ label, value, onChange, onBlur, options, tabOrder = 0 }) => {
    return (
        <Form.Group controlId="ticketDate" className='mt-2'>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as="select"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                tabIndex={tabOrder}
            >
                <option value="" disabled selected hidden>Select {label}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </Form.Control>
        </Form.Group>
    );
};

export const NumberInput = ({ label, value, onChange, onBlur, placeholder, min, max }) => {
    return (
        <InputGroup controlId={label} className='mt-2'>
            <InputGroup.Text>{label}</InputGroup.Text>
            <Form.Control
                type="number"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                min={min}
                max={max}
            />
        </InputGroup>
    );
};

export const SelectWithSideLabel = ({ label, value, onChange, onBlur, options, tabOrder = 0 }) => {
    return (
        <InputGroup controlId={label} className='mt-2'>
            <InputGroup.Text>{label}</InputGroup.Text>
            <Form.Control
                as="select"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                tabIndex={tabOrder}
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </Form.Control>
        </InputGroup>
    );
};

export const InputWithSideLabelAndFactor = ({ label, value, onChange, onBlur, placeholder, isDisabled = false, type = 'text', autoFocus = false, tabOrder = 0, factor = 1 }) => {

    return (
        <InputGroup className="mt-2">
            <InputGroup.Text>{label}</InputGroup.Text>
            <Form.Control
                type={type}
                placeholder={placeholder}

                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                autoFocus={autoFocus}
                tabIndex={tabOrder}
            />
            <InputGroup.Text id="basic-addon2">{factor}</InputGroup.Text>
        </InputGroup>
    );
}

export const InputWithSideLabelAndButton = ({ label, value, onChange, onBlur, placeholder, isDisabled = false, type = 'text', autoFocus = false, tabOrder = 0, onClick, buttonIcon, button }) => {
    return (
        <InputGroup className='mt-2'>
            <InputGroup.Text>{label}</InputGroup.Text>
            <Form.Control
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                autoFocus={autoFocus}
                tabIndex={tabOrder}
            />
            <Button variant="outline-success" onClick={onClick}>
                {buttonIcon && <FontAwesomeIcon icon={buttonIcon} />}
                {' '}
                {button}
            </Button>
        </InputGroup>
    );
}

export const InputAreaWithSideLabel = ({ label, value, onChange, onBlur, placeholder, isDisabled = false, type = 'text', autoFocus = false, tabOrder = 0 }) => {
    return (
        <InputGroup className='mt-2'>
            <InputGroup.Text>{label}</InputGroup.Text>
            <Form.Control
                as="textarea"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={isDisabled}
                autoFocus={autoFocus}
                tabIndex={tabOrder}
                spellCheck="true"
                // Column and row count
                cols="50"
                rows={6}
            />
        </InputGroup>
    );
}