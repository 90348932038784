import React, { useState, useEffect, useCallback } from 'react';
import { Card, ListGroup, Button, Form, Dropdown, Modal, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsisV, faPenToSquare, faTrash, faTag, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FaMobileScreenButton } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { IoMailOpenOutline } from "react-icons/io5";

import CompanyModal from './companyModal';
import api from '../../services/ApiConfig';
import AddressModal from './AddressModal';
import ContactModal from './ContactModal';
import TagModal from './TagModal';

function AddressBook({ darkMode }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [companyModal, setCompanyModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [addressBookData, setAddressBookData] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [contactModal, setContactModal] = useState(false);
    const [tagModal, setTagModal] = useState(false);
    const [confirmationType, setConfirmationType] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);

    const COMPANY_URL = '/companies';

    const ADDRESS_URL = '/addresses';

    useEffect(() => {
        fetchAddressBookData();
    }, []);


    useEffect(() => {
        // If there's a selected company, find its corresponding object in the data
        if (selectedCompany !== null && addressBookData.length > 0) {
            const selectedCompanyObject = addressBookData.find(company => company.id === selectedCompany.id);
            if (selectedCompanyObject) {
                setSelectedCompany(selectedCompanyObject);
            }
        } else if (addressBookData.length > 0) {
            // If no selected company or the selected company is not found, select the first one
            setSelectedCompany(addressBookData[0]);
        }
    }, [selectedCompany, addressBookData]);

    const fetchAddressBookData = async () => {
        try {
            const response = await api.get(COMPANY_URL);
            setAddressBookData(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }; // The empty dependency array ensures that this function is only created once

    const handleDeleteCompany = async () => {
        setItemToDelete(selectedCompany);
        handleShowConfirmationModal('company');
    };

    const handleShowConfirmationModal = (type) => {
        setConfirmationType(type);
        setConfirmationModal(true);
    };

    const handleConfirmDelete = async () => {
        try {

            switch (confirmationType) {
                case 'company':
                    deleteCompany();
                    break;
                case 'address':
                    deleteAddress();
                    break;
                case 'contact':
                    deleteContact();
                    break;
                default:
                    break;
            }

            fetchAddressBookData();
        } catch (error) {
            console.error(error);
        }

        setItemToDelete(null);
        setConfirmationType(null); // Reset confirmation type
        setConfirmationModal(false);
    };

    const handleCancelDelete = () => {
        setConfirmationModal(false);
    };

    // Address Modal -----------------------------
    const showAddAddress = () => {
        console.log("Show Add Address")
        setEditMode(false);
        setAddressModal(true);

    };

    const hideAddressModal = () => {
        setEditMode(false);
        setAddressModal(false);
        fetchAddressBookData(); // This will now use the memoized callback
    };

    const showEditAddress = (address) => {
        console.log("Show Edit Address")
        setEditMode(true);
        setSelectedAddress(address);
        setAddressModal(true);


    };

    const deleteAddress = async () => {

        let address = itemToDelete;

        try {
            await api.delete(`${ADDRESS_URL}/${address.attributes.address_id}`);
            fetchAddressBookData();
            console.log('Address deleted')
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteAddress = async (address) => {
        setItemToDelete(address);
        handleShowConfirmationModal('address');
    };

    // Company Modal -----------------------------

    const deleteCompany = async () => {

        let company = itemToDelete;

        try {
            await api.delete(`${COMPANY_URL}/${company.attributes.company_id}`);
            fetchAddressBookData();
        } catch (error) {
            console.error(error);
        }
    };

    const showAddCompany = () => {
        setEditMode(false);
        setCompanyModal(true);
    };

    const showEditCompany = () => {
        setEditMode(true);
        setCompanyModal(true);
    };

    const hideCompanyModal = () => {
        setEditMode(false);
        setCompanyModal(false);
        fetchAddressBookData(); // This will now use the memoized callback
    };

    const handleCompanyClick = (company) => {
        console.log("handleCompanyClick", company)
        setSelectedCompany(company);
    };



    // Contact Modal -----------------------------

    const showAddContact = () => {
        console.log("Show Add Contact")
        setEditMode(false);
        setContactModal(true);
    };

    const hideContactModal = () => {
        setEditMode(false);
        setContactModal(false);
        fetchAddressBookData();
    };

    const showEditContact = (contact) => {
        console.log("Show Edit Contact")
        setEditMode(true);
        setSelectedContact(contact);
        setContactModal(true);
    };


    const handleDeleteContact = async (contact) => {
        setItemToDelete(contact);
        handleShowConfirmationModal('contact');
    };

    const deleteContact = async () => {
        try {
            await api.delete(`/contacts/${itemToDelete.attributes.contact_id}`);
            fetchAddressBookData();
        }
        catch (error) {
            console.error(error);
        }
    };


    // Tag Modal -----------------------------
    const hideTagModal = () => {
        setTagModal(false);
        setEditMode(false);
        fetchAddressBookData();
    };

    const showTagModal = () => {
        console.log("Show Tag Edit")
        setTagModal(true);
    };

    return (
        <div>
            <div>
                <div className='mb-2'>
                    <h1>Address Book</h1>
                    <h6 className={darkMode ? ' ' : 'text-muted'}>
                        Manage your address book
                    </h6>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-2">
                                <Button
                                    className="harvyst-green"
                                    onClick={showAddCompany}
                                >
                                    <FontAwesomeIcon icon={faPlus} /> Add Company
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search for companies, people, tags..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <h2>Companies</h2>
                        <ListGroup>
                            {addressBookData && addressBookData.length > 0 ? (
                                addressBookData.map((company) => (
                                    <ListGroup.Item
                                        key={company.id}
                                        onClick={() => handleCompanyClick(company)}
                                        active={selectedCompany === company}
                                        variant={darkMode ? 'dark' : 'light'}
                                        className='d-flex justify-content-between align-items-center cursor-pointer'
                                    >
                                        <div>
                                            {company.attributes.company_name}, <span>{company.attributes.cusno}</span>
                                        </div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="blank" id="dropdown-basic" className={selectedCompany === company ? 'text-white' : 'text-black'}>
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={showAddAddress}><FontAwesomeIcon icon={faPlus} /> Add Address</Dropdown.Item>
                                                <Dropdown.Item onClick={showAddContact}><FontAwesomeIcon icon={faPlus} /> Add Contact</Dropdown.Item>
                                                <Dropdown.Item onClick={showTagModal}><FontAwesomeIcon icon={faTag} /> Edit Tag</Dropdown.Item>
                                                <Dropdown.Item onClick={showEditCompany}>
                                                    <FontAwesomeIcon icon={faPenToSquare} /> Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item className='text-danger' onClick={handleDeleteCompany}>
                                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <p>No companies found.</p>
                            )}
                        </ListGroup>
                    </div>

                    <div className="col-md-8">
                        {selectedCompany && (
                            <div>
                                <div className="d-flex justify-content-between">
                                    <h2>{selectedCompany.attributes.company_name}</h2>

                                </div>
                                {selectedCompany.attributes.addresses.map((address, addressIndex) => (
                                    <div className="row align-items-center" key={addressIndex}>
                                        <div className="col">
                                            <p>
                                                {address.attributes.address_name} {address.attributes.address_attribute ? `(${address.attributes.address_attribute})` : ''}: {address.attributes.address1}
                                                {address.attributes.address2 ? `, ${address.attributes.address2}` : ''}
                                                {address.attributes.town ? `, ${address.attributes.town}` : ''}
                                                {address.attributes.province_code ? `, ${address.attributes.province_code}` : ''}
                                                {address.attributes.country_code ? `, ${address.attributes.country_code}` : ''}
                                                {address.attributes.postal_code ? `, ${address.attributes.postal_code}` : ''}
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="blank" id="dropdown-basic">
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => showEditAddress(address)}>
                                                        <FontAwesomeIcon icon={faPenToSquare} /> Edit
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='text-danger' onClick={() => handleDeleteAddress(address)}>

                                                        <FontAwesomeIcon icon={faTrash} /> Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                ))}
                                <p className={darkMode ? '' : 'text-muted'}>
                                    {selectedCompany.attributes.tags.map((tag, tagIndex) => (
                                        <span key={tagIndex}>{tag.attributes.company_tag} </span>
                                    ))}
                                </p>
                                <div className="contact-cards-container">
                                    {selectedCompany.attributes.contacts && selectedCompany.attributes.contacts.length > 0 ? (
                                        <Row xs={1} md={2} lg={3} className="g-4">
                                            {selectedCompany.attributes.contacts.map((contact, contactIndex) => (
                                                <Col key={contactIndex} className='border p-3 m-2 rounded'>

                                                    <div className="d-flex justify-content-between">
                                                        <strong>{contact.attributes.title} {contact.attributes.contact_name}</strong>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="blank" id={`dropdown-${contactIndex}`}>
                                                                <FontAwesomeIcon icon={faEllipsisV} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => showEditContact(contact)}>
                                                                    <FontAwesomeIcon icon={faPenToSquare} /> Edit
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className='text-danger' onClick={() => handleDeleteContact(contact)}>
                                                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>

                                                    <span className=''>{contact.attributes.primary_contact ? 'Primary' : ''}</span>

                                                    <div><IoMailOpenOutline />{' '}
                                                        <a href={`mailto:${contact.attributes.email}`}>{contact.attributes.email}</a></div>
                                                    <div><FiPhone />{' '} <a href={`tel:${contact.attributes.phone}`}>{contact.attributes.phone}</a>
                                                        {contact.attributes.cell ? (
                                                            <div><FaMobileScreenButton />{' '}<a href={`tel:${contact.attributes.cell}`}>{contact.attributes.cell}</a></div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div>{contact.attributes.notes}</div>

                                                </Col>
                                            ))}
                                        </Row>
                                    ) : (
                                        <div>No contacts</div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div >
                </div >
            </div>

            {companyModal &&
                <CompanyModal
                    show={companyModal}
                    hide={hideCompanyModal}
                    editMode={editMode}
                    companyToEdit={selectedCompany}
                />
            }

            {addressModal &&
                <AddressModal
                    show={addressModal}
                    hide={hideAddressModal}
                    editMode={editMode}
                    company={selectedCompany}
                    address={selectedAddress}
                ></AddressModal>
            }
            {contactModal &&
                <ContactModal
                    show={contactModal}
                    hide={hideContactModal}
                    editMode={editMode}
                    company={selectedCompany}
                    contact={selectedContact}
                ></ContactModal>
            }

            {tagModal &&
                <TagModal
                    show={tagModal}
                    hide={hideTagModal}
                    company={selectedCompany}
                />
            }




            <Modal show={confirmationModal} onHide={handleCancelDelete}>
                <Modal.Header>
                    <Modal.Title>Are you sure you would like to delete?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        This action cannot be undone. The information may still be shown on transactions.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >

    );
}

export default AddressBook;