import React, { useEffect, useState } from 'react';
import {Card} from 'react-bootstrap';
import axios from 'axios';

const Updates = () => {
    const [latestUpdates, setLatestUpdates] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        // Simulating fetching updates from the API
        const fakeUpdates = [
            {
                id: 1,
                title: 'New Feature Release',
                version: '1.2.0',
                body: 'We are excited to announce the release of a new feature that enhances your grain management experience.',
                link: 'https://example.com/update/1',
            },
            {
                id: 2,
                title: 'Bug Fixes',
                version: '1.1.2',
                body: 'This update includes important bug fixes and performance improvements.',
                link: 'https://example.com/update/2',
            },
            {
                id: 3,
                title: 'Maintenance Notice',
                body: 'Our system will undergo scheduled maintenance on August 15th from 10:00 PM to 2:00 AM (UTC).',
            },
            // Add more update objects...
        ];

        // Get the latest 3 updates
        const latest3Updates = fakeUpdates.slice(0, 3);
        setLatestUpdates(latest3Updates);
    }, []);

    return (
        <div>
            <Card style={{ width:'18rem'}}>
                <Card.Body>
                    <Card.Title>Going Live</Card.Title>
                    <Card.Subtitle>{currentTime.toLocaleTimeString()}</Card.Subtitle>
                    <Card.Text>Online in 2024</Card.Text>
                    <Card.Link>www.harvyst.ag</Card.Link>
                </Card.Body>
            </Card>
        </div>
    );

/*
    return (
        <div className={`panel updates`}>
            <h3>Harvyst Updates</h3>
            <div className="row">
                <div className="col-md-12">
                    <ul className="list-group">
                        {latestUpdates.map(update => (
                            <li key={update.id} className={`list-group-item ${darkMode ? 'dark-mode-accent' : ''}`}>
                                <h5>{update.title}</h5>
                                {update.version && <p>Version: {update.version}</p>}
                                <p>{update.body}</p>
                                {update.link && <a href={update.link} target="_blank" rel="noopener noreferrer">Read more</a>}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
    */
}

export default Updates;