
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faCheckCircle, faXmarkCircle, faTrash, faPenToSquare, faFolderOpen, faEye } from '@fortawesome/free-solid-svg-icons';
import '../../styles/news.css';

const NewsEntry = ({ news, onEdit, onDelete, onSetActive, onSetArchive, darkMode }) => {
    const { title, body, createdStamp, modifiedStamp, active, isArchived } = news;

    return (
        <tr className={`news-entry ${active ? ' active ' : ' inactive '} ${isArchived ? 'table-light' : ''}`}>
            < td > {title}</td >
            <td>{body}
                <br />
                <br />
                {createdStamp && (
                    <span className="modified-stamp fw-lighter small">Created: {createdStamp}</span>
                )}

                {modifiedStamp && (
                    <span className="modified-stamp fw-lighter small"> Edited: {modifiedStamp}</span>
                )}

            </td>
            <td>
                <div className="d-flex align-items-center">
                    {active ? (
                        <FontAwesomeIcon icon={faCheckCircle} className='status-active' />
                    ) : (
                        <FontAwesomeIcon icon={faXmarkCircle} className='status-inactive' />
                    )}
                    <Dropdown>
                        <Dropdown.Toggle variant="blank" id="dropdown-basic">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onEdit(news)} className=''><FontAwesomeIcon icon={faPenToSquare} /> Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => onDelete(news)} className='text-danger'><FontAwesomeIcon icon={faTrash} /> Delete</Dropdown.Item>
                            <Dropdown.Item onClick={() => onSetActive(news)}><FontAwesomeIcon icon={faEye} />
                                {active ? ' Set Inactive' : ' Set Active'}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onSetArchive(news)}><FontAwesomeIcon icon={faFolderOpen} />
                                {isArchived ? ' Restore' : ' Archive'}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

            </td>
        </tr >
    );
};

export default NewsEntry;