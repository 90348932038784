import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import getCommodityIcon from './CommodityIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../components/LoadingSpinner';

const baseURL = 'http://192.168.1.41:5000/cashbid';

const Cashbid = () => {
    const { id } = useParams();
    const [cashbidData, setCashbidData] = useState(null);
    const [volumesList, setVolumesList] = useState([]);
    const [isArchived, setIsArchived] = useState(null);
    console.log(cashbidData)

    const handleArchiveChange = (e) => {
        setIsArchived(e.target.checked);
    };

    useEffect(() => {
        axios
            .get(`${baseURL}/${id}`)
            .then((response) => {
                setCashbidData(response.data);
                setVolumesList(response.data.volumes || []);
                setIsArchived(response.data.isArchived);
            })
            .catch((error) => console.error('Error fetching cash bid details:', error));
    }, [id]);


    return (
        <Container className="my-4">
            {cashbidData ? (
                <div>
                    <h2>{cashbidData.commodity} {getCommodityIcon(cashbidData.commodity)}</h2>
                    <p className="text-muted">Ref: {cashbidData.id}</p>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-start align-items-md-center">
                        <Button variant="primary" className='harvyst-green'><FontAwesomeIcon icon={faPenToSquare} /> Edit Cash Bid</Button>
                        <Button variant="danger" className="mx-0 mx-sm-1 mt-2 mt-sm-0"><FontAwesomeIcon icon={faTrash} /> Delete Cash Bid</Button>
                        <Button variant="secondary" href="/cashbids" className="mt-2 mt-sm-0"><FontAwesomeIcon icon={faArrowLeft} /> Back to Cash Bids</Button>
                        <Form.Check
                            className="mx-2 mt-2 mt-sm-0"
                            type="checkbox"
                            label="Archive Cash Bid"
                            id="archive-switch"
                            checked={isArchived}
                            onChange={handleArchiveChange}
                        />
                    </div>
                    <hr />
                    <Row className="my-4">
                        <Col md={6}>
                            <Container>
                                {cashbidData ? (
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <p><strong>Delivery:</strong> {cashbidData.deliveryDate}</p>
                                                <p><strong>Expiry:</strong> {cashbidData.expiry}</p>
                                                <p><strong>Location:</strong> {cashbidData.location}</p>
                                                <p><strong>Crop Year:</strong>{cashbidData.cropYear}</p>
                                                <p><strong>Futures:</strong> {cashbidData.futures}</p>
                                                <p><strong>Basis:</strong> {cashbidData.basis}</p>
                                                <p><strong>Price:</strong> {cashbidData.price} {cashbidData.unit}</p>
                                                <p><strong>Minimum Volume:</strong> {cashbidData.minVolume}</p>
                                                <p><strong>Maximum Volume:</strong> {cashbidData.maxVolume}</p>
                                                <p><strong>Comments:</strong> {cashbidData.comments}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    <LoadingSpinner />
                                )}
                            </Container>
                        </Col>
                        <Col md={6}>
                            <h5>Volumes</h5>
                            {volumesList.length > 0 ? (
                                <Table striped bordered responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Volume</th>
                                            <th>Farmer</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {volumesList.map((volume, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{volume.volume}</td>
                                                <td>{volume.farmer}</td>
                                                <td>{volume.date}</td>
                                                <td>{volume.time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>No volumes yet.</p>
                            )}
                        </Col>
                    </Row>
                </div>
            ) : (
                <p>Loading cash bid details...</p>
            )}
        </Container>
    );
};

export default Cashbid;
