import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../../styles/login.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { app } from '../../services/firebase';
import { GoogleAuthProvider, browserLocalPersistence, browserSessionPersistence, setPersistence, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useAuthContext } from '../../services/AuthContext';

const Login = () => {
    const {user, signIn, signInWithGoogle} = useAuthContext();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    // Checks if there is a user, if there is then the login screen is skipped and user is redirected to the home page
    useEffect(() => {
        console.log('useEffect login.js');

    }, []);

    const loginRedirect = () =>{
        if (user.emailVerified) {
            navigate('/home');
        } else {
            setLoginError('Please verify your email before logging in.');
            // Optionally, you can also resend the verification email if the user hasn't verified yet.
            // For example: await sendEmailVerification(user);
        }    
    }


    const handleLogin = async (e) => {
        e.preventDefault();
        setDisableButton(true); 
        setLoginError('');
        try{
            await signIn(email, password);
            
            loginRedirect();
        
        } catch (error) {

            console.error(error.message);
            setLoginError(error.message);

        }finally{
            setDisableButton(false);
        }

    };

    const handelLoginInWithGoogle = async (e) => {
        e.preventDefault();
        setDisableButton(true);
        setLoginError('');
        try {
            const result = await signInWithGoogle();
            console.log('Google Sign-In Result:', result);

            loginRedirect();

        } catch (error) {
            console.error(error.message);
            setLoginError(error.message);
        } finally{
            setDisableButton(false);
        }
    };

    return (
        <div className="login-container">
            <video autoPlay preload muted loop className="background-video">
                <source src="/images/marketing/elevator-drone-720.webm" type="video/webm" />
                <source src="/images/marketing/elevator-drone-og.mp4" type="video/mp4" />
            </video>
            <Container>
                <Row className="justify-content-center align-items-center login-box">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <h1 className="text-center mb-4"><img src={process.env.PUBLIC_URL + "/images/logos/harvyst.svg"} alt="Harvyst logo" className="img-fluid mb-3" width={200} height={35} /></h1>
                        <Form onSubmit={handleLogin}>
                            {loginError && <p className="text-danger">{loginError}</p>}

                            <Form.Group controlId="formEmail" className='my-2'>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete='email'
                                />
                            </Form.Group>
                            <Form.Group controlId="formPassword" className='my-2'>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete='current-password'

                                />
                            </Form.Group>
                            <Form.Group controlId="formCheckbox" className='my-2'>
                                <Form.Check type="checkbox" label="Remember me"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                            </Form.Group>

                            <Form.Group block className='my-2'>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    block
                                    className='ml-1'
                                    disabled={disableButton}
                                >
                                    {disableButton ? (
                                        <>
                                            Logging In...
                                            <div className="spinner-border text-light spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </>
                                    ) : (
                                        'Login'
                                    )}
                                </Button>

                                <Button variant="success" href="/signup" block className='mx-1'>
                                    Sign Up
                                </Button>
                            </Form.Group>
                            <Form.Group controlId="formForgotPassword" className='my-2'>
                                <Form.Text className="text-muted">
                                    <a href="/forgot-password">Forgot password?</a>
                                </Form.Text>
                            </Form.Group>
                        </Form>
                        {/* <hr className='mt-4' />
                        <div className="google-login">
                            <Button variant="light" className="login-provider-button px-2 border-primary" hover onClick={handelLoginInWithGoogle}>
                                <img src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" height={35} alt="google icon" />
                                <span> Continue with Google</span>
                            </Button>
                        </div> */}

                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default Login;
