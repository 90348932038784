import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Form, Button, Spinner } from 'react-bootstrap';

const StickyBar = ({ isFetching, netWeight, handleSaveTicket, errors, warnings }) => {


    return (
        <div className="fixed-bottom bg-dark text-white p-4 d-flex justify-content-between align-items-center">
            <div className=''>
                {isFetching && (
                    <p>
                        Calculating...
                        <Spinner animation="border" variant="light" size="sm" className="ml-2" />
                    </p>
                )}


                {!isFetching && (
                    <>
                        <p>
                            <FontAwesomeIcon icon={faCircleCheck} /> Saved
                        </p>

                        {errors.slice(-3).map((error, index) => (
                            <p key={index} style={{ color: 'red', margin: 0 }}>
                                {error}
                            </p>
                        ))}

                        {warnings.slice(-3).map((warning, index) => (
                            <p key={index} style={{ color: 'yellow', margin: 0 }}>
                                {warning}
                            </p>
                        ))}
                    </>
                )}
            </div>

            <p className="m-0">
                <strong>Net Weight:</strong> {netWeight.toFixed(4)}
            </p>

            <div className="">
                <Button variant="primary" onClick={handleSaveTicket} disabled={isFetching}>
                    Save Ticket
                </Button>

            </div>
        </div>
    );
}

export default StickyBar;