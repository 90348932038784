import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'react-bootstrap';
import { useAuthContext } from '../../../services/AuthContext'
import router from 'webstomp/dist/router';


const GreetingsPanel = () => {
    
    const { user } = useAuthContext();
    const [currentTime, setCurrentTime] = useState(new Date());

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour >= 5 && hour < 12) {
            return 'Good Morning';
        } else if (hour >= 12 && hour < 17) {
            return 'Good Afternoon';
        } else {
            return 'Good Evening';
        }
    };

/*
    useEffect(() => {
    }, []);
*/

    return (
        <div>
            <Card style={{  width:'18rem'}}>
                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to='/account'>
                <Card.Body>
                    <Card.Title>{getGreeting()}</Card.Title>
                    <Card.Subtitle>{currentTime.toLocaleTimeString()}</Card.Subtitle>
                    <Card.Text>{user.email}</Card.Text>
                    <Card.Link>Account</Card.Link>
                </Card.Body>
                </Link>
            </Card>
        </div>
    );

/*
    return (
        <div className={`panel updates`}>
            <h3>Harvyst Updates</h3>
            <div className="row">
                <div className="col-md-12">
                    <ul className="list-group">
                        {latestUpdates.map(update => (
                            <li key={update.id} className={`list-group-item ${darkMode ? 'dark-mode-accent' : ''}`}>
                                <h5>{update.title}</h5>
                                {update.version && <p>Version: {update.version}</p>}
                                <p>{update.body}</p>
                                {update.link && <a href={update.link} target="_blank" rel="noopener noreferrer">Read more</a>}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
    */
}

export default GreetingsPanel;