import React, { useState } from 'react';
import { Button, Modal, Form, FormGroup } from 'react-bootstrap';

const CreateNewsModal = ({ show, onHide, onPublish }) => {
    const [newstitle, setNewsTitle] = useState('');
    const [newsBody, setNewsBody] = useState('');
    const [active, setactive] = useState(true);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (newsBody.trim() === '' || newstitle.trim() === '') {
            alert('Please fill in both the title and body fields.');
        } else {
            onPublish(newstitle, newsBody, active);
            setNewsTitle('');
            setNewsBody('');
            setactive(true);
            onHide();
        }
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Create News Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter title"
                            value={newstitle}
                            onChange={(e) => setNewsTitle(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Form.Label>Body</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter news content"
                            value={newsBody}
                            onChange={(e) => setNewsBody(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Form.Check
                            type="checkbox"
                            label="Active Status"
                            checked={active}
                            onChange={(e) => setactive(e.target.checked)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button className="harvyst-green" type="submit">
                            Publish
                        </Button>
                        <Button variant="secondary" className='mx-1' onClick={onHide}>
                            Cancel
                        </Button>
                    </FormGroup>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateNewsModal;
