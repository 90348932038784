// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-entry .dropdown-toggle::after {
  content: none;
}

.news-entry {
  vertical-align: middle;
}

.news-entry .status-active {
  color: var(--harvyst-green-light);
}

.news-entry .status-inactive {
  color: var(--harvyst-red);
}

.news-entry.inactive {
  background-color: var(--inactive);
}

.news-entry .archived {
  background-color: green !important ;
}

/* Styles for mobile devices */
@media (max-width: 767px) {
  .news-entry td,
  .news-entry th {
    font-size: 12px;
  }

  .news-entry td {
    white-space: wrap;
    word-break: normal;
  }

  .news-table th {
    display: none;
  }

  .news-entry td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/news.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,mCAAmC;AACrC;;AAEA,8BAA8B;AAC9B;EACE;;IAEE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,cAAc;EAChB;AACF","sourcesContent":[".news-entry .dropdown-toggle::after {\n  content: none;\n}\n\n.news-entry {\n  vertical-align: middle;\n}\n\n.news-entry .status-active {\n  color: var(--harvyst-green-light);\n}\n\n.news-entry .status-inactive {\n  color: var(--harvyst-red);\n}\n\n.news-entry.inactive {\n  background-color: var(--inactive);\n}\n\n.news-entry .archived {\n  background-color: green !important ;\n}\n\n/* Styles for mobile devices */\n@media (max-width: 767px) {\n  .news-entry td,\n  .news-entry th {\n    font-size: 12px;\n  }\n\n  .news-entry td {\n    white-space: wrap;\n    word-break: normal;\n  }\n\n  .news-table th {\n    display: none;\n  }\n\n  .news-entry td::before {\n    content: attr(data-label);\n    font-weight: bold;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
