
//import useState hook to create menu collapse state
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import '../styles/sidenav.css';

//import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from "react-pro-sidebar";
import { useAuthContext } from '../services/AuthContext';

//import icons from react icons
import { FaNewspaper, FaChartLine, FaRegAddressCard } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { IoTicketOutline } from "react-icons/io5";
import { BiCog } from "react-icons/bi";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { GiLevelEndFlag } from "react-icons/gi";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "../styles/base.css";
import "../styles/sidenav.css";


const SideNav = ({ children }) => {
    const {user, settings, logout} = useAuthContext();

    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };


    const handleSignOut = async () => {
        try {
            console.log('Signing out');
            //            signOut();
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };
    const closeMenu = () => {

    };

    const MenuLink = ({ title, to, icon}) =>{
        const cn = (window.location.pathname == to) ? 'navitem-active':''

        return <MenuItem className={cn}  onClick={() => { closeMenu() }} icon={icon}>
            {title}
            <Link to={to} />
        </MenuItem>        
    };

    const location = useLocation();
    const navigate = useNavigate();


    return (
        <>
            <div id="app-container" >
                {/* collapsed props to change menu size using menucollapse state */}
                <div id='sidenav-div'>
                    <ProSidebar collapsed={menuCollapse}>
                        <SidebarHeader>
                            <div className="logotext">
                                {/* small and big change using menucollapse state */}
                                {/*<p>{menuCollapse ? "Logo" : "Big Logo"}</p> */}
                            </div>
                            <div className="closemenu" onClick={menuIconClick}>
                                {/* changing menu collapse icon on click */}
                                {menuCollapse ? (
                                    <FiArrowRightCircle />
                                ) : (
                                    <FiArrowLeftCircle />
                                )}
                            </div>
                        </SidebarHeader>
                        <SidebarContent>
                            <Menu iconShape="square" >
                                <MenuLink title='Home' icon={<FiHome />} to="/home" />
                                <MenuLink title='News' icon={<FaNewspaper />} to="/news" className='red-guy' />
                                <MenuLink title='Addresses' icon={<FaRegAddressCard />} to="/addressbook" />
                                <MenuLink title='Tickets' icon={<IoTicketOutline />} to="/tickets" />
                                <MenuLink title='Ticket Board' icon={<LiaChalkboardTeacherSolid />} to="/ticketboard" />
                                <MenuLink title='Inventory' icon={<GiLevelEndFlag />} to="/inventory" />
                                <MenuLink title='Markets' icon={<FaChartLine />} to="/markets" />
                                <MenuLink title='Settings' icon={<BiCog />} to="/settings" />
                            </Menu>
                        </SidebarContent>
                        <SidebarFooter>
                            <Menu iconShape="square">
                                <MenuItem icon={<FiLogOut />} onClick={handleSignOut}>Logout</MenuItem>
                            </Menu>
                        </SidebarFooter>
                    </ProSidebar>
                </div>
                <div id='app-children'>
                    {children}
                </div>
            </div>

        </>
    );
};

export default SideNav;
/*
                                <MenuLink active={true} title='Home' icon={<FiHome />} to="/home" />
                                <MenuLink title='News' icon={<FaNewspaper />} to="/news" className='red-guy' />
                                <MenuLink title='Addresses' icon={<FaRegAddressCard />} to="/addressbook" />
                                <MenuLink className='navitem-active' title='Tickets' icon={<IoTicketOutline />} to="/tickets" />
                                <MenuLink title='Ticket Board' icon={<LiaChalkboardTeacherSolid />} to="/ticketboard" />
                                <MenuLink title='Inventory' icon={<GiLevelEndFlag />} to="/inventory" />
                                <MenuLink title='Markets' icon={<FaChartLine />} to="/markets" />
                                <MenuLink title='Settings' icon={<BiCog />} to="/settings" />
        <MyMenuItem icon={<FaCoins />} text="Budget" to="/budget" />
        <MyMenuItem icon={<FaDollarSign />} text="Income" to="/income" />
        <MyMenuItem icon={<FaCog />} text="Setting" to="/setting" />                                
                                <MenuLink active={false} title='Home' icon={<FiHome />} to="/home" />
                                <MenuLink active={true} activeClassName='navitem-active' title='News' icon={<FaNewspaper />} to="/news" className='red-guy' />

                <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'navitem-active' : ''}> 
                    <MenuItem icon={<FiHome />}>Home</MenuItem>
                </Nav.Link>                
                <Nav.Link as={Link} to="/news" className={location.pathname === '/news' ? 'navitem-active' : ''}> 
                    <MenuItem icon={<FaNewspaper />}>News</MenuItem>
                </Nav.Link>
                <Nav.Link as={Link} to="/addressbook" className={location.pathname === '/addressbook' ? 'navitem-active' : ''}> 
                    <MenuItem icon={<FaRegAddressCard />}>Addresses</MenuItem>
                </Nav.Link>         
                <Nav.Link as={Link} to="/tickets" className={location.pathname === '/tickets' ? 'navitem-active' : ''}> 
                    <MenuItem icon={<IoTicketOutline />}>Tickets</MenuItem>
                </Nav.Link>                
                <Nav.Link as={Link} to="/markets" className={location.pathname === '/markets' ? 'navitem-active' : ''}> 
                    <MenuItem icon={<FaChartLine />}>Markets</MenuItem>
                </Nav.Link>                
                <Nav.Link as={Link} to="/settings" className={location.pathname === '/settings' ? 'navitem-active' : ''}> 
                    <MenuItem icon={<BiCog />}>Settings</MenuItem>
                </Nav.Link>                
                                
                */
