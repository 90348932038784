// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* New Ticket Screen */
#tickets-div{
    width:100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/tickets.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,UAAU;AACd","sourcesContent":["/* New Ticket Screen */\n#tickets-div{\n    width:100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
