import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert, Image } from 'react-bootstrap';
import validator from 'validator';
import { app } from '../../services/firebase';
import { Navigate, Link, useParams } from 'react-router-dom';
import '../../styles/signup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import api from '../../services/ApiConfig';

const UserSignup = () => {
    const { token } = useParams();
    const [company, setCompany] = useState('Harvyst');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [redirectTo404, setRedirectTo404] = useState(false);
    const [signup, setSignup] = useState(false);


    const handleSignup = async () => {

        setSignup(true);

        const errors = validateUser();
        setErrorMessages(errors);

        if (errors.length === 0) {
            try {
                const auth = app.auth();

                // Use createUserWithEmailAndPassword once
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                const user = userCredential.user;

                // Send email verification
                await sendEmailVerification(user);

                try {
                    const res = await api.get(`/registration/user/${token}`);
                    setRedirectToLogin(true); // Redirect to the home page
                }
                catch (error) {
                    console.error('Error fetching data:', error.message);
                    setRedirectTo404(true);
                }

            } catch (error) {
                setSignup(false);
                console.error('Error signing up:', error.message);
                setErrorMessages([error.message]);
            }
        }
    };

    const fetchData = async (token) => {
        try {
            const res = await api.get(`/registration/landing/${token}`);
            console.log(res.status)

            if (res.status === 200) {
                setCompany(res.data.data.harvyst_name);
            } else {
                console.error('Unexpected status code:', res.status);
                setRedirectTo404(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
            setRedirectTo404(true);

        }
    };

    useEffect(() => {
        if (token) {
            fetchData(token);
        }
    }, [token]); // Include 'token' in the dependency array


    const validateUser = () => {
        const errors = [];
        if (!validator.isEmail(email)) {
            errors.push('Invalid email address.');
        }

        if (!validator.isLength(password, { min: 6 })) {
            errors.push('Password must be at least 6 characters.');
        }

        if (password !== passwordConfirmation) {
            errors.push('Passwords do not match.');
        }

        return errors;
    };


    if (redirectToLogin) {
        return <Navigate to="/login" />;
    }

    if (redirectTo404) {
        return <Navigate to="/404" />;
    }


    return (
        <Container fluid className="full-height">
            <Row className="full-height">
                <Col xs={6} className="left-column">
                    <img src="/images/field.jpg" alt="Field" />
                </Col>
                <Col xs={6} className="right-column">
                    <div className="centered-content">
                        <div className="text-center mb-4">
                            <img src={process.env.PUBLIC_URL + "/images/logos/harvyst.svg"} alt="Logo" className="img-fluid" width={250} height={35} />
                        </div>
                        <h2>You have been invited to join {company}!</h2> {/* Replace XYZ Company with the company name*/}
                        {errorMessages.length > 0 && (
                            <Alert variant="danger">
                                {errorMessages.map((message, index) => (
                                    <p key={index}>{message}</p>
                                ))}
                            </Alert>
                        )}
                        <Form>
                            <Row>
                                <Col md={12}>

                                    <Form.Group className="mt-2" controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            isInvalid={email && !validator.isEmail(email)}
                                            autoComplete='email'

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email address.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mt-2" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            isInvalid={password.length > 0 && password.length < 6}
                                            autoComplete='new-password'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Password must be at least 6 characters.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mt-2" controlId="formBasicPasswordConfirmation">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Confirm password"
                                            value={passwordConfirmation}
                                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                                            isInvalid={passwordConfirmation && password !== passwordConfirmation}
                                            autoComplete='new-password'
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Passwords do not match.
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Link to="/login">
                                <Button variant="primary" className="mt-2 harvyst-green">
                                    <FontAwesomeIcon icon={faArrowLeft} />  Back to Login
                                </Button>
                            </Link>

                            <Button
                                variant="primary"
                                type="submit"
                                block
                                className='mt-2 mx-2'
                                disabled={signup}
                                onClick={handleSignup}
                            >
                                {signup ? (
                                    <>
                                        Signing up...
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </>
                                ) : (
                                    'Sign up'
                                )}
                            </Button>

                        </Form>

                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default UserSignup;
