import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { sendEmailVerification } from 'firebase/auth';
import { app } from '../../services/firebase';



const VerifyEmail = () => {

    const handleEmailVerification = async () => {
        try {

            const auth = app.auth();
            const user = auth.currentUser; // Assuming you have the auth object from Firebase
            await sendEmailVerification(user);
            // You might want to show a success message or redirect the user after sending the verification email
        } catch (error) {
            console.error("Error sending email verification:", error);
            // Handle the error, e.g., show an error message to the user
        }
    };


    // Ensures that the user is logged out when they arrive at this page. That way they will be forced to verify their login with credentials
    useEffect(() => {
        const handleLogout = async () => {
            const auth = app.auth();
            try {
                await auth.signOut();
            } catch (error) {
                console.error("Error during logout:", error);
            }
        };

        handleLogout();
    }, []);



    return (
        <div className="container text-center mt-5">
            <div className="row">
                <div className="">
                    <img src="/images/logos/harvyst.svg" alt="Harvey Logo" className="img-fluid mb-5" width={250} />
                </div>
                <div className="">
                    <img src="/images/email-verification/mailbox.svg" alt="Mailbox" className="img-fluid" width={250} />
                </div>
            </div>

            <h1 className="mb-3">Verification sent to your email.</h1>

            <p className="mb-4">
                We sent you an email with a link to verify your email. If you need to resend the verification{' '}
                <a href="#" onClick={handleEmailVerification}>
                    click here.
                </a>
                .
            </p>

            <p>
                Contact{' '}
                <a href='mailto:support@harvyst.ag?subject=Email Verification Issue'>
                    support@harvyst.ag
                </a>{' '}
                if this continues.
            </p>

            <p>
                <a href='/login' className="btn btn-primary">Go to Login</a>.
            </p>
        </div>
    );
};

export default VerifyEmail;
