import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, ListGroup, Form, Table, Button } from 'react-bootstrap';
import validator from 'validator';
import api from '../../services/ApiConfig';


function CompanyManager(darkMode) {
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [stateProvince, setStateProvince] = useState('');
    const [editingPersonal, setEditingPersonal] = useState(true);
    const [errorMessages, setErrorMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState('');
    const [inviteEmail, setInviteEmail] = useState('');
    const [inviteName, setInviteName] = useState('');
    const [inviteErrors, setInviteErrors] = useState([]);
    const [role, setRole] = useState('admin');

    const handleAddUser = async () => {
        // Validate the invite details
        const errors = validateInvite();

        if (errors.length === 0) {
            try {
                // API call to add user
                const res = await api.post('/company/invite', {
                    email: inviteEmail,
                    name: inviteName,
                    role: role,
                });

                // Assuming the API returns a success message
                console.log('User invited successfully:', res.data.message);

                // May want to update the users state with the new user here if needed ??

                // Clear input fields
                setInviteEmail('');
                setInviteName('');
                setRole('admin');
            } catch (error) {
                console.error('Error inviting user:', error.message);
            }
        }
        else {
            setInviteErrors(errors);

        }
    };


    const validateInvite = () => {
        const errors = [];

        if (!validator.isEmail(inviteEmail)) {
            errors.push('Please enter a valid email.');
        }

        if (!inviteName) {
            errors.push('Please enter a name.');
        }

        setErrorMessages(errors);
        return errors;
    }


    const handleDeleteUser = (userId) => {
        // Delete user logic here
    };

    const handleEditUserRole = (userId) => {
        // Edit user role logic here
    };

    const handelSave = async () => {
        const errors = [];

        if (!country) {
            errors.push('Please select a country.');
        }

        if (country === 'Canada' && !stateProvince) {
            errors.push('Please select a province.');
        }

        if (country === 'US' && !stateProvince) {
            errors.push('Please select a state.');
        }

        if (country === 'US' && !validator.isPostalCode(postalCode, 'US')) {
            errors.push('Invalid US zip code.');
        }

        if (country === 'Canada' && !validator.isPostalCode(postalCode, 'CA')) {
            errors.push('Invalid Canadian postal code.');
        }

        setErrorMessages(errors);

        if (errors.length === 0) {
            try {
                // API Call to save changes
            } catch (error) {
                console.error('Error saving personal information:', error.message);
                setErrorMessages([error.message]);
            }
        }
    };

    useEffect(() => {
        // API calls to get company info, users, and other data
        // Update the 'company', 'address', 'postalCode', 'country', 'stateProvince', and 'users' state variables accordingly
    }, []);


    return (
        <div>
            <h1>Company Manager</h1>

            <Row>
                <Col>
                    <h2>Information</h2>
                    <Form>

                        <Form.Group className="mt-2" controlId="formBasicCompany">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                autoComplete='organization-title'
                                disabled={!editingPersonal
                                }
                            />
                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                autoComplete='street-address'
                                disabled={!editingPersonal}
                            />
                        </Form.Group>


                        <Form.Group className="mt-2" controlId="formBasicCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as="select"
                                value={country}
                                autoComplete='country'
                                onChange={(e) => setCountry(e.target.value)}
                                disabled={!editingPersonal}
                            >
                                <option value="">Select a country</option>
                                <option value="Canada">Canada</option>
                                <option value="US">US</option>

                            </Form.Control>

                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicPostalCode">
                            <Form.Label>{country === 'US' ? 'Zip Code' : 'Postal Code'}</Form.Label>
                            <Form.Control
                                type="text"
                                autoComplete='postal-code'
                                placeholder={`Enter your ${country === 'US' ? 'zip code' : 'postal code'}`}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                isInvalid={postalCode && !validator.isPostalCode(postalCode, country === 'US' ? 'US' : 'CA')}
                                disabled={!editingPersonal}
                            />
                            <Form.Control.Feedback type="invalid">
                                {country === 'US'
                                    ? 'Please enter a valid US zip code.'
                                    : 'Please enter a valid Canadian postal code.'}
                            </Form.Control.Feedback>

                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicStateProvince">
                            <Form.Label>{country === 'US' ? 'State' : 'Province'}</Form.Label>
                            <Form.Control
                                as="select"
                                value={stateProvince}
                                onChange={(e) => setStateProvince(e.target.value)}
                                disabled={!editingPersonal}

                            >
                                <option value="">Select a {country === 'US' ? 'state' : 'province'}</option>
                                {/* Populate options based on selected country */}
                                {country === 'Canada' && (
                                    <optgroup label="Canada">
                                        <option value="Ontario">Ontario</option>
                                        <option value="Quebec">Quebec</option>
                                        <option value="Nova Scotia">Nova Scotia</option>
                                        <option value="New Brunswick">New Brunswick</option>
                                        <option value="Manitoba">Manitoba</option>
                                        <option value="British Columbia">British Columbia</option>
                                        <option value="Prince Edward Island">Prince Edward Island</option>
                                        <option value="Saskatchewan">Saskatchewan</option>
                                        <option value="Alberta">Alberta</option>
                                        <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                        <option value="Northwest Territories">Northwest Territories</option>

                                    </optgroup>
                                )}
                                {country === 'US' && (
                                    <optgroup label="US">
                                        <option value="Alabama">Alabama</option>
                                        <option value="Alaska">Alaska</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="Arkansas">Arkansas</option>
                                        <option value="California">California</option>
                                        <option value="Colorado">Colorado</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Delaware">Delaware</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Illinois">Illinois</option>
                                        <option value="Indiana">Indiana</option>
                                        <option value="Iowa">Iowa</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="Kentucky">Kentucky</option>
                                        <option value="Louisiana">Louisiana</option>
                                        <option value="Maine">Maine</option>
                                        <option value="Maryland">Maryland</option>
                                        <option value="Massachusetts">Massachusetts</option>
                                        <option value="Michigan">Michigan</option>
                                        <option value="Minnesota">Minnesota</option>
                                        <option value="Mississippi">Mississippi</option>
                                        <option value="Missouri">Missouri</option>
                                        <option value="Montana">Montana</option>
                                        <option value="Nebraska">Nebraska</option>
                                        <option value="Nevada">Nevada</option>
                                        <option value="New Hampshire">New Hampshire</option>
                                        <option value="New Jersey">New Jersey</option>
                                        <option value="New Mexico">New Mexico</option>
                                        <option value="New York">New York</option>
                                        <option value="North Carolina">North Carolina</option>
                                        <option value="North Dakota">North Dakota</option>
                                        <option value="Ohio">Ohio</option>
                                        <option value="Oklahoma">Oklahoma</option>
                                        <option value="Oregon">Oregon</option>
                                        <option value="Pennsylvania">Pennsylvania</option>
                                        <option value="Rhode Island">Rhode Island</option>
                                        <option value="South Carolina">South Carolina</option>
                                        <option value="South Dakota">South Dakota</option>
                                        <option value="Tennessee">Tennessee</option>
                                        <option value="Texas">Texas</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Vermont">Vermont</option>
                                        <option value="Virginia">Virginia</option>
                                        <option value="Washington">Washington</option>
                                        <option value="West Virginia">West Virginia</option>
                                        <option value="Wisconsin">Wisconsin</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </optgroup>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select a {country === 'US' ? 'state' : 'province'}.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" className='my-2 harvyst-green' onClick={handelSave}>
                            Save
                        </Button>

                    </Form>

                </Col>


                <Col md={6}>
                    <h2>Company Users</h2>
                    <Form>
                        <h3>Invite a new user</h3>
                        <Form.Group controlId="formBasicNewUser">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Add a new user by email"
                                value={inviteEmail}
                                onChange={(e) => setInviteEmail(e.target.value)}
                                onBlur={() => setInviteErrors(validateInvite())} // Trigger validation on blur
                                isInvalid={inviteErrors.length > 0 && inviteErrors.includes('Please enter a valid email.')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {inviteErrors.length > 0 && inviteErrors.includes('Please enter a valid email.') && 'Please enter a valid email.'}
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group controlId="formBasicNewUserName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name of user name"
                                value={inviteName}
                                onChange={(e) => setInviteName(e.target.value)}
                                onBlur={() => setInviteErrors(validateInvite())} // Trigger validation on blur
                                isInvalid={inviteErrors.length > 0 && inviteErrors.includes('Please enter a name.')}
                            />
                            <Form.Control.Feedback type="invalid">
                                {inviteErrors.length > 0 && inviteErrors.includes('Please enter a name.') && 'Please enter a name.'}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className='my-2'>
                            <Form.Label>Role</Form.Label>
                            <Form.Control as="select" value={role} onChange={(e) => setRole(e.target.value)}>
                                <option value="admin">Admin</option>
                                <option value="manager">Manager</option>
                                <option value="employee">Employee</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Button variant="primary" className='my-2 harvyst-green' onClick={handleAddUser}>
                                Invite
                            </Button>
                        </Form.Group>
                    </Form>


                    <h3>Current Users</h3>
                    <Table striped bordered hover>

                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <Button variant="danger" onClick={() => handleDeleteUser(user.id)}>
                                            Delete
                                        </Button>
                                        <Button variant="primary" onClick={() => handleEditUserRole(user.id)}>
                                            Edit Role
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div >
    );
};


export default CompanyManager;
