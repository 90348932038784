import './styles/app.css';
import './styles/base.css';
import { Routes, Route } from 'react-router-dom';
import React from 'react';
import { useToast } from './services/ToastContext';
import Toast from './components/ErrorToast';

import Home from "./pages/home/Home";
import News from './pages/news/News';
import Cashbid from "./pages/cashbids/Cashbid";
import Login from "./pages/login/Login";
import Settings from './pages/settings/Settings';
import Inventory from './pages/inventory/inventory';
import MarketData from './pages/marketData/MarketData';
import AddressBook from './pages/addressBook/AddressBook';
import TicketBoard from './pages/ticketboard/ticketboard';
import Signup from './pages/signup/Signup';
import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from './pages/login/ForgotPassword';
import NotFound from './components/404NotFound';
import Account from './pages/account/Account';
import UserSignup from './pages/signup/UserSignup';
import CompanyManager from './pages/companyManager/CompanyManager';
import Tickets from './pages/tickets/tickets';
import Cashbids from './pages/cashbids/Cashbids';
import Landing from './pages/landing/landing';
import VerifyEmail from './pages/login/VerifyEmail';
import NewTicket from './pages/tickets/newTicket';
import { AuthContextProvider } from './services/AuthContext';
import { DataProvider } from './services/DataContext';

export default function App() {
  return (
    <div >
      <AuthContextProvider>
        <DataProvider>
          <Toast />
          <Routes>
            {/* Protected Routes */}
            <Route path="/home" element={<ProtectedRoute><Home/></ProtectedRoute>} />
            <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>} />
            <Route path="/news" element={<ProtectedRoute><News/></ProtectedRoute>} />
            <Route path="/cashbids" element={<ProtectedRoute><Cashbids/></ProtectedRoute>} />
            <Route path="/cashbid/:id" element={<ProtectedRoute><Cashbid/></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings/></ProtectedRoute>} />
            <Route path="/markets" element={<ProtectedRoute><MarketData/></ProtectedRoute>} />
            <Route path="/addressbook" element={<ProtectedRoute><AddressBook/></ProtectedRoute>} />
            <Route path='/tickets' element={<ProtectedRoute><Tickets/></ProtectedRoute>} />
            <Route path='/ticketboard' element={<ProtectedRoute><TicketBoard/></ProtectedRoute>} />
            <Route path='/inventory' element={<ProtectedRoute><Inventory/></ProtectedRoute>} />
            <Route path='/ticket/:id' element={<ProtectedRoute element={null} />} />
            <Route path='/account' element={<ProtectedRoute><Account/></ProtectedRoute>} />
            <Route path='/manage' element={<ProtectedRoute><CompanyManager/></ProtectedRoute>} />
            {/*<Route path='/user-invite' element={<ProtectedRoute><UserInvite/></ProtectedRoute>} /> */}
            <Route path='/new-ticket/:id?' element={<ProtectedRoute><NewTicket/></ProtectedRoute>} />
            
            {/* End of Protected Routes */}

            {/* Public Routes */}
            <Route path="/landing" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/:token" element={<UserSignup />} /> {/* This route is used for user signup, the token would be the unique identifier to match with the company (To add users)*/}
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path='*' element={<NotFound />} />
            {/* End of Public Routes */}
          </Routes>
        </DataProvider>
      </AuthContextProvider>

    </div>
  );
}

