import React, { useEffect } from 'react';
import { useToast } from '../services/ToastContext';
import { Toast, ToastContainer } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';

const ErrorToast = ({ title, message, id }) => {
    const { removeToast } = useToast();

    return (
        <Toast onClose={() => removeToast(id)} show bg='danger'>
            <Toast.Header>
                <FaExclamationTriangle className="me-2" />
                <strong className="me-auto">{title}</strong>
                <small className="text-muted">
                    {new Date().toLocaleTimeString()}
                </small>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
};

const ToastManager = () => {
    const { toasts } = useToast();

    return (
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            {toasts.map((toast) => (
                <ErrorToast key={toast.id} {...toast} />
            ))}
        </ToastContainer>
    );
};

export default ToastManager;
