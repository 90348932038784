import React from 'react';
import { Card } from 'react-bootstrap';
import getCommodityIcon from './CommodityIcon';
import '../../styles/cashbids.css'; // Import the CSS file

const CashbidCard = ({ cashbid }) => {
    const { commodity, deliveryDate, expiry, location, cropYear, basis, unit, currency, minVolume, maxVolume, comments, isArchived } = cashbid;

    return (
        <div className="cashbid-card-container" >

            <Card className="cashbid-card" border={isArchived ? 'dark' : ''} bg={isArchived ? 'light' : ''}>
                <Card.Body>
                    <Card.Title className="card-title">
                        {getCommodityIcon(commodity)} {commodity}
                    </Card.Title>

                    <hr />
                    <Card.Text>
                        <strong>Delivery:</strong> {deliveryDate}
                    </Card.Text>
                    <Card.Text>
                        <strong>Expiry:</strong> {expiry}
                    </Card.Text>
                    <Card.Text>
                        <strong>Location:</strong> {location}
                    </Card.Text>
                    <Card.Text>
                        <strong>Crop Year:</strong> {cropYear}
                    </Card.Text>
                    <Card.Text>
                        <strong>Basis:</strong> ${basis} ({currency})
                    </Card.Text>
                    <Card.Text>
                        <strong>Unit:</strong> {unit}
                    </Card.Text>
                    <Card.Text>
                        <strong>Min Volume:</strong> {minVolume}
                    </Card.Text>
                    {maxVolume && (
                        <Card.Text>
                            <strong>Max Volume:</strong> {maxVolume}
                        </Card.Text>
                    )}
                    <Card.Text>
                        <strong>Comments:</strong> {comments}
                    </Card.Text>
                </Card.Body>
            </Card>

        </div>
    );
};

export default CashbidCard;
