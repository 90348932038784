// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default styles for larger screens */
body,
html {
  height: 100%;
  margin: 0;
}

.full-height {
  min-height: 100%;
  display: flex;
}

.left-column {
  position: sticky;
  top: 0;
  padding: 0;
  overflow: hidden;
  background-color: #f0f0f0;
  height: 100vh;
  display: flex;
  align-items: bottom;
  justify-content: center;
}

.image-container {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.right-column {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  padding: 3rem;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .left-column {
    display: none; /* Hide the left column on smaller screens */
  }

  .right-column {
    width: 100vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/signup.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;;EAEE,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,aAAa;AACf;;AAEA,gCAAgC;AAChC;EACE;IACE,aAAa,EAAE,4CAA4C;EAC7D;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":["/* Default styles for larger screens */\nbody,\nhtml {\n  height: 100%;\n  margin: 0;\n}\n\n.full-height {\n  min-height: 100%;\n  display: flex;\n}\n\n.left-column {\n  position: sticky;\n  top: 0;\n  padding: 0;\n  overflow: hidden;\n  background-color: #f0f0f0;\n  height: 100vh;\n  display: flex;\n  align-items: bottom;\n  justify-content: center;\n}\n\n.image-container {\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n}\n\n.right-column {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #f0f0f0;\n  padding: 3rem;\n}\n\n/* Media query for mobile view */\n@media (max-width: 768px) {\n  .left-column {\n    display: none; /* Hide the left column on smaller screens */\n  }\n\n  .right-column {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
