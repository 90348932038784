import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Table, Form, Button, OverlayTrigger, Tooltip, Card, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenToSquare, faEllipsisV, faFolderOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import TicketEntry from './components/ticketEntry';
import { useToast } from '../../services/ToastContext';
import api from '../../services/ApiConfig';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useDataContext } from '../../services/DataContext';

function TicketScreen({ darkMode }) {
    const [showArchived, setShowArchived] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [rawTickets, setRawTickets] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { addErrorToast } = useToast();
    const { commodity_data, company_data } = useDataContext();

    const navigate = useNavigate();


    const ticketFactory = () => {
        try {
            console.log('In Factory', rawTickets);
            const formattedTickets = rawTickets.map((rawTicket) => {
                const attributes = rawTicket.attributes;
                const commodity = commodity_data.find((c) => c.commodity_id === attributes.commodity_id);
                const company = company_data.find((c) => c.company_id === attributes.company_id);

                return {
                    ticketNumber: attributes.ticket_id,
                    transactionType: attributes.transaction_type,
                    netWeight: attributes.net_weight,
                    scaleId: attributes.scaleticket,
                    companyName: company ? company.company_name : '',
                    commodity: commodity ? commodity.commodity : '',
                    date: attributes.ticket_dt,
                    is_deleted: attributes.is_deleted,
                };
            });

            setTickets(formattedTickets);
            console.log("Formatted Tickets", formattedTickets)
        } catch (error) {
            console.error('Error processing tickets:', error);
        }
    };

    const fetchRawTickets = async () => {
        try{
            setIsLoading(true);
            const res = await api.get('/tickets');
            setRawTickets(res.data.data);
            // THIS WILL NOT NOT NOT SHOW the rawTickets .. as it wont be avail until the next render!
            //console.log("Raw Tickets ppp", rawTickets)       
            // I spent about 8 hours on this problems   
            //Exactly, as Phil said, React will ensure that you see the result of setState in the next render. So using the value in render will make it visible. – 
        } catch (error) {
            console.error('Error fetching raw tickets:', error);
            addErrorToast('Error fetching raw tickets', 'API Error');
        } finally {
            setIsLoading(false);
        }
    }
   
    // Whenever this page is loaded.
    useEffect(() => {        
        fetchRawTickets();
    }, []);

    // Anytime rawTickets is changed.
    useEffect(() => {
        ticketFactory();
    }, [rawTickets]);

    const toggleArchived = () => {
        setShowArchived(!showArchived);
    };



    const filteredTickets = tickets.filter((ticket) => {
        const matchSearch = searchTerm
            ? ticket.attributes.ticket_id.toLowerCase().includes(searchTerm.toLowerCase())
            : true;
//        const matchArchived = showArchived ? true : !ticket.attributes.is_deleted;
        const matchArchived = showArchived ? true : !ticket.is_deleted;
        return matchSearch && matchArchived;
    });

    //
    // Callbacks from ticketEntry
    const removeTicketCB = useCallback((tn) => {
        console.log('Removing ' + tn);
        setRawTickets(rawTickets.filter(item => item.id !== tn));
    });

    const editTicketCB = useCallback((tn) => {
        console.log('Editing ' + tn);

        const res = api.get('/tickets/'+tn)
        .then(function (res){
            //console.log('UserSettings THEN ', response.data);
            if((res.status == 200) && (res.data.data[0].attributes.transaction_type === 'hold')){ 
                // Assuming there's always at least one ticket in the response
                console.log('Got ticket ID ', res.data.data[0].attributes.ticket_id);
                navigate('/new-ticket/' + tn);
            }
            else{
                addErrorToast('You cannot edit a non-hold ticket');
                console.log('Ticket not editable');
            }
        })
        .catch(function (error) {
            addErrorToast('Error retrieving ticket details ');
            console.log('Error retrieving ticket details ', error);
        });

        // Get ticket.  If ticket is !hold
//        navigate('/new-ticket/' + tn);
    });

    const startNewTicket =  () => {
        try {

            const res = api.post('/tickets', {
                "ticket_dt": new Date().toISOString().split('T')[0]
            })
            .then(function (res){
                console.log(res);
                if(res.status == 200){
                    // Assuming there's always at least one ticket in the response
                    console.log('Got ticket ID ', res.data.data[0].attributes.ticket_id);
                    navigate('/new-ticket/' + res.data.data[0].attributes.ticket_id);
                }
            })
            .catch(function (error) {
                console.log('Error loading new ticket settings ', error);
            });
            
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <div id='tickets-div'>
            <div className='mb-2'>
                <h1>Tickets</h1>
                <h6 className={darkMode ? ' ' : 'text-muted'}>
                    Track tickets for your commodities. Click on a ticket to view more details.
                </h6>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-2">
                            {/*<Link to='/new-ticket' className='button'> */}
                                <Button className="harvyst-green" onClick={() => startNewTicket()}>
                                    <FontAwesomeIcon icon={faPlus} />
                                    Create New Ticket
                                </Button>
                            {/*</Link>*/}
                           
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={<Tooltip className='button-tooltip'>Archive will hide away items without deleting them</Tooltip>}
                            >
                                <Form.Check
                                    className=""
                                    type="switch"
                                    label="Show Archived"
                                    id="archive-switch"
                                    checked={showArchived}
                                    onChange={toggleArchived}
                                />
                            </OverlayTrigger>
                            <Form.Group controlId="formSearch">
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Form.Group>
                        </div>

                    </div >
                </div >
            </div >
            <div className="d-none d-md-block">
                <Table striped bordered hover variant={darkMode ? 'dark' : ''}>
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Company</th>
                            <th>Commodity</th>
                            <th>Type</th>
                            <th>Net</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="8" className="text-center"><LoadingSpinner /></td>
                            </tr>
                        ) : (
                            filteredTickets.map((ticket, index) => (
                                <TicketEntry key={index}  ticket={ticket} removeTicketCB={removeTicketCB} editTicketCB={editTicketCB}/>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
            {/* Display Cards on Smaller Screens */}
            <div className="d-md-none">
                {isLoading ? (
                    <span className="text-center"><LoadingSpinner /></span>
                ) : (
                    filteredTickets.map((ticket, index) => (
                        <TicketEntry key={index} ticket={ticket} asCard={true} removeTicketCB={removeTicketCB} editTicketCB={editTicketCB} />
                    ))
                )}
            </div>
        </div>
    );
};

export default TicketScreen;
