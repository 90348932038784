import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import validator from 'validator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';
import { app } from '../../services/firebase';
import { EmailAuthProvider, AuthCredential } from 'firebase/auth';

const Account = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [editing, setEditing] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [googleSignin, setGoogleSignin] = useState(false);    // If they are signed in with Google, they cannot change their password
    const [successMessage, setSuccessMessage] = useState('');

    const showPasswordModalHandler = () => {
        setShowPasswordModal(true);
    };

    // Function to hide the password modal
    const hidePasswordModalHandler = () => {
        setShowPasswordModal(false);
    };

    const handleSave = async () => {

        const errors = [];


        if (!validator.isMobilePhone(phone, 'any')) {
            errors.push('Invalid phone number.');
        }

        if (!validator.isEmail(email) && !googleSignin) {
            errors.push('Invalid email address.');
        }

        if (!validator.isLength(password, { min: 6 }) && !googleSignin) {
            errors.push('Password must be at least 6 characters.');
        }

        if (password !== passwordConfirmation && !googleSignin) {
            errors.push('Passwords do not match.');
        }

        setErrorMessages(errors);

        if (errors.length === 0) {
            try {

                // Reauthenticate user
                const currentUser = app.auth().currentUser;
                const currentEmail = currentUser.email;

                const credential = EmailAuthProvider.credential(
                    currentEmail,
                    currentPassword
                );

                await currentUser.reauthenticateWithCredential(credential);

                if (email !== currentEmail) {
                    await currentUser.updateEmail(email);
                }

                if (password !== '') {
                    await currentUser.updatePassword(password);
                }

                if (name) {
                    // Add API to change name
                }

                if (phone) {
                    // Add API to change phone
                }

                setEditing(false);
                setShowPasswordModal(false);
                setSuccessMessage('User information updated successfully.');

            } catch (error) {
                setShowPasswordModal(false);
                console.error('Error updating user information:', error.message);
                setErrorMessages([error.message]);
            }

        }
    };

    useEffect(() => {
        /*
        // Check the authentication providers associated with the user
        const currentUser = app.auth().currentUser;
        const providers = currentUser.providerData.map((profile) => profile.providerId);

        // Update the googleSignin state based on whether 'google.com' is in the providers array
        setGoogleSignin(providers.includes('google.com'));
        setEmail(currentUser.email);
        setName("Gavin");
        setPhone("1234567890");
        */

    }, []);


    return (
        <div className="centered-content">
            <h2>Account</h2>
            {errorMessages.length > 0 && (
                <Alert variant="danger">
                    {errorMessages.map((message, index) => (
                        <p key={index}>{message}</p>
                    ))}
                </Alert>
            )}
            {successMessage && (
                <Alert variant="success">
                    {successMessage}
                </Alert>
            )}

            <Form>
                <Row>
                    <Col md={6}>
                        <h3 className="mt-2">Personal Information</h3>
                        <Form.Group className="mt-2" controlId="formBasicName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                isInvalid={errorMessages.includes('Name is required')}
                                autoComplete='name'
                                disabled={!editing}
                            />
                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicPhone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Enter your phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                isInvalid={phone && !validator.isMobilePhone(phone, 'any')}
                                autoComplete='tel'
                                disabled={!editing}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid phone number.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                isInvalid={email && !validator.isEmail(email)}
                                autoComplete='email'
                                disabled={!editing || googleSignin}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid email address.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                isInvalid={password.length > 0 && password.length < 6}
                                autoComplete='new-password'
                                disabled={!editing}
                                hidden={googleSignin}
                            />
                            <Form.Control.Feedback type="invalid">
                                Password must be at least 6 characters.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mt-2" controlId="formBasicPasswordConfirmation">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                isInvalid={passwordConfirmation && password !== passwordConfirmation}
                                autoComplete='new-password'
                                disabled={!editing}
                                hidden={googleSignin}
                            />
                            <Form.Control.Feedback type="invalid">
                                Passwords do not match.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {googleSignin ?
                            <Form.Group className="mt-2" controlId="SigninType" >
                                <Form.Label>Sign in through Google. Cannot change email or password.</Form.Label>
                            </Form.Group> : null
                        }
                        <Form.Group className="mt-3">
                            {editing ? (
                                <div>
                                    <Button variant="primary" className="my-2" onClick={showPasswordModalHandler}>
                                        <FontAwesomeIcon icon={faSave} /> Save
                                    </Button>
                                    <Button variant="secondary" className="my-2 mx-2" onClick={() => setEditing(false)}>
                                        <FontAwesomeIcon icon={faArrowLeft} /> Cancel
                                    </Button>
                                </div>
                            ) : (
                                <Button variant="primary" className="my-2" onClick={() => setEditing(true)}>
                                    <FontAwesomeIcon icon={faEdit} /> Edit
                                </Button>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={6}>

                        <h3 className='mt-2'>Service Information</h3>

                        <Form.Group className="mt-2" controlId="formBasicRole">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Error loading role"
                                value="Full"
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="mt-2" controlId="formBasicHarvystID">
                            <Form.Label>Harvyst ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Error loading Harvyst ID"
                                value="1"
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="mt-2" controlId="formBasicUUID">
                            <Form.Label>UUID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Error loading UUID"
                                value={app.auth().currentUser.uid}
                                disabled
                            />
                        </Form.Group>

                    </Col>
                </Row>
                <Modal show={showPasswordModal} onHide={hidePasswordModalHandler}>
                    <Form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <Modal.Header closeButton>
                            <Modal.Title>Authenticate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="formBasicCurrentPassword">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    autoComplete='current-password'
                                    placeholder="Enter your current password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={hidePasswordModalHandler}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            </Form>


        </div>
    );
};

export default Account;
