// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-lbl {
    /*color: '#212529';*/
    color:#313539;
}
.card-val {
    color: #111519;
    font-weight:bold;
    font-size: 1.1em;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ticketboard.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,aAAa;AACjB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".card-lbl {\n    /*color: '#212529';*/\n    color:#313539;\n}\n.card-val {\n    color: #111519;\n    font-weight:bold;\n    font-size: 1.1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
