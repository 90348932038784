import { faChartLine, faCloudArrowUp, faFileInvoice, faFileSignature, faHeadset, faUsers, faPhone, faPlayCircle, faWeightScale, faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState, useEffect } from 'react';
import ErrorBox from '../../components/ErrorBox';
import NoticeBox from '../../components/NoticeBox';

import api from '../../services/ApiConfig';


import {
    Dropdown,
    Nav,
    Navbar,
    NavDropdown,
    Button,
    Modal,
    Container,
    Row,
    Col,
} from 'react-bootstrap';
export default function Landing() {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [notice, setNotice] = useState('');
    const features = [

        {
            "name": "Tickets",
            "description": "Efficiently manage and track grain tickets.  Share, export and analyze data.",
            "icon": faFileInvoice
        },
        {
            name: "Contracts",
            description: "Create, monitor and manage grain contracts. Evaluate position.",
            icon: faFileSignature
        },
        {
            name: "Multiple Users",
            description: "Collaborate with team members. Share and divide data and responsibility, with defined roles.",
            icon: faUsers
        },
        {
            name: "Cloud Technologies",
            description: "Ensure the safety and accessibility of your data with automatic cloud backups, security audits to prevent data loss.",
            icon: faCloudArrowUp
        },
        {
            name: "Target Pricing",
            description: "Stay informed with up-to-the-minute market data.  Create and manage target orders.",
            icon: faCommentsDollar /*faChartLine*/
        },
        {
            name: "Auto Scaling",
            description: "Securely capture weights and complete tickets directly from your phone.",
            icon: faWeightScale
        },
        {
            name: "Inventory",
            description: "Always know your inventory and your market position.",
            icon: faHeadset
        },
        {
            name: "Helpful Support",
            description: "Access customer support with real humans who can provide assistance and guidance when needed.",
            icon: faHeadset
        },        

    ];

    const screenshotImages = [
        //"/images/marketing/S1.png",
        //"/images/marketing/S2.png",
        //"/images/marketing/S3.png",
        "/images/marketing/S2mid.png",
        "/images/marketing/S3mid.png",
        "/images/marketing/S4mid.png",
        "/images/marketing/S5mid.png",
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);

    const updateImageIndex = () => {
        setOpacity(0); // Set opacity to 0 for the fade-out effect
        setTimeout(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % screenshotImages.length);
            setOpacity(1); // Set opacity back to 1 for the fade-in effect
        }, 300); // Adjust the duration as needed
    };

    // Use useEffect to update the image index every 5 seconds (adjust the duration as needed)
    useEffect(() => {
        const intervalId = setInterval(updateImageIndex, 5000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    const isSupportAvailable = () => {
        const currentDate = new Date();
        const currentDay = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        const currentHour = currentDate.getHours();

        // Check if it's a weekday and between 9 AM to 5 PM
        return currentDay >= 1 && currentDay <= 5 && currentHour >= 9 && currentHour < 17;
    };

    const handleCallButtonClick = () => {
        if (isSupportAvailable()) {
            // Add logic for handling phone call during support hours
            alert('Calling support...');
        } else {
            alert('Support is available Monday to Friday, 9 AM to 5 PM.');
        }
    };

    const handleEmailButtonClick = () => {
        // Add logic for handling email support
        alert('Sending email to support...');
    };



    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const emailREGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;


    const handleJoinWaitlist = (e) => {
        e.preventDefault();
        if(email.match(emailREGEX)){
            setIsValidEmail(true);
            //alert('Joining waitlist');
            const res = api.post('/registration/joinwaitlist', {
                "email": email
            });
            setEmail('');
            setNotice(email + ' has subscribed to the waitlist.')
        }else{
            setIsValidEmail(false);
            setNotice('');
        }

        // Add logic for handling the email for the waitlist
        //alert(`Joining waitlist with email: ${email}`);
    };


    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div >
            {/* Nav */}
            <header className=" ">
                <Navbar bg="white" className='py-3 fs-5' expand="lg">
                    <Container>
                        <Navbar.Brand href="#">
                            <img
                                alt="logo"
                                src="images/logos/harvyst.svg"
                                className="d-inline-block align-center"
                                width={200}
                                height={25}
                            />{' '}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" />
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="#features" className="text-gray-900">
                                    Features
                                </Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link href="#waitlist" className="text-gray-900">
                                    <Button className='cta'>
                                        Join the Waitlist
                                    </Button>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Modal show={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Harvyst</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Nav className="mt-2">
                            <Nav.Link href="#features" className="text-gray-900">
                                Features
                            </Nav.Link>
                        </Nav>

                    </Modal.Body>
                </Modal>
            </header>

            {/* Hero */}
            <div fluid className="py-3 px-3 py-md-3 my-0" id="hero">
                <Row className="relative overflow-hidden d-xl-block d-xxl-flex">
                    <Col xxl={6} className="text-center d-flex flex-column justify-content-center px-5">
                        <h2 className='display-5 display-md-4'>
                            Supercharge Your Grain Management.
                            <br />
                            Elevate Productivity with <span className='harvyst-green-text font-weight-bold'>Harvyst</span>.
                        </h2>
                        <p className="mt-4 fs-5">
                            Streamline your grain management process and boost efficiency effortlessly.
                            Embrace precision and control with our user-friendly app. Save time & money with accurate reporting.
                        </p>
                        <div className="m-4">
                            <Button href="#waitlist" className="m-3 cta fs-5">
                                Join the Waitlist
                            </Button>
                            <Button variant="outline-secondary fs-5" href="#features">
                                Learn more <span aria-hidden="true">→</span>
                            </Button>
                        </div>
                    </Col>
                    
                {/**}
                </Row><Row className="relative overflow-hidden">xxl="auto"
                {/**/}
                    <Col xxl={6} className="px-auto py-4 d-none d-md-flex text-center justify-content-center h">

                        <img
                            style={{ opacity, transition: 'opacity 0.8s'}}
                            className="mw-100 rounded-3 bg-white shadow-lg"
                            src={screenshotImages[currentImageIndex]}
                            alt="App screenshot"
                        />
                    </Col>
                </Row>
            </div>




            {/* Features */}
            <div className="py-5 py-md-5 my-0" id='features'>
                <div className="container justify-item-center">
                    <div className="mx-auto max-w-xxl text-center">
                        <h2 className="text-uppercase font-weight-bold harvyst-green-text">Fully Featured</h2>
                        <p className="mt-2 lead font-weight-bold text-light">
                        </p>
                        <p className="mt-4 lead">
                            Software that offers grain elevators a fully automated solution to inventory control and accounting. Harvyst gives elevator managers accurate positioning information in real-time, removing guesswork during negotiations and inventory decision making in one user-friendly package.
                        </p>
                    </div>
                    <div className="mx-auto mt-5 max-w-xxl mt-md-5 fs-5" >
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            {features.map((feature) => (
                                <div key={feature.name} className="col ">
                                    <div className=" border-0 ">
                                        <Row xs={1} md={2}>
                                            {/* Icon Column (20% width) */}
                                            <Col lg={3} xs={3} className="text-center text-white" >
                                                <span className=' rounded-3 bg-success p-2'><FontAwesomeIcon icon={feature.icon} className="" aria-hidden="true" /></span>
                                            </Col>


                                            {/* Title and Text Columns (80% width) */}
                                            <Col xs={9} className='text-left '>
                                                {/* Title */}
                                                <h5 className="mb-2 font-weight-bold fs-4 ">{feature.name}</h5>

                                                {/* Description */}
                                                <p className="text-secondary">{feature.description}</p>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

    
            {/* Waitlist */}
            <div className="container mt-5" id='waitlist'>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="card p-5">
                            <div className="card-body">
                                <h2 className="card-title">Join Our Waitlist</h2>
                                <p className="card-text">
                                    Exciting things are coming! Be the first to know when we launch. Enter your email below to join our waitlist.
                                </p>
                                <div className="input-group mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    <div className="input-group-append mx-2">
                                        <button className="btn btn-primary cta" type="button" onClick={handleJoinWaitlist}>
                                            Join Waitlist
                                        </button>
                                    </div>
                                </div>
                                {!isValidEmail && <ErrorBox errorMsg='Invalid Email'></ErrorBox>}
                                {notice != '' && <NoticeBox noticeMsg={notice}></NoticeBox> }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-dark text-light mt-5 py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p>&copy; 2023 Harvyst. All rights reserved.</p>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <p>Contact: support@harvyst.ag | Phone: (855) 947-2467</p>
                        </div>
                    </div>
                </div>
            </footer>



        </div>
    )
};


