import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';


const EditNewsModal = ({ show, onHide, news, onSave }) => {
    const [editedNews, setEditedNews] = useState({ ...news });

    const handleSave = () => {
        // Update the modifiedStamp with the current timestamp
        editedNews.modifiedStamp = new Date().toLocaleString();
        onSave(editedNews);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Edit News Entry</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={editedNews.title}
                            onChange={(e) => setEditedNews({ ...editedNews, title: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Body</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={editedNews.body}
                            onChange={(e) => setEditedNews({ ...editedNews, body: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="checkbox"
                            label="Active Status"
                            checked={editedNews.active}
                            onChange={(e) => setEditedNews({ ...editedNews, active: e.target.checked })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Created: {editedNews.createdStamp}</Form.Label>
                        <br />
                        {editedNews.modifiedStamp && <Form.Label>Last Edited: {editedNews.modifiedStamp}</Form.Label>}

                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditNewsModal;