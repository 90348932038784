import React, { useEffect, useState } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import api from '../../services/ApiConfig';

function TagModal({ show, hide, company }) {
    const [tagsList, setTagsList] = useState([]);
    const [checked, setChecked] = useState([]);

    const TAGS_URL = '/companytags';
    const TAG_ASSOCIATION_URL = '/companytagassociation';

    useEffect(() => {
        console.log(company);
        fetchTags();
        // Set initially checked tags based on existing associations
        setChecked(
            company.attributes.tags.map((tag) => tag.attributes.company_tag_id)
        );
    }, [company]);

    const fetchTags = async () => {
        try {
            const response = await api.get(TAGS_URL);
            setTagsList(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };
    const handleCheckboxChange = (tagId, isChecked) => {
        const association = company.attributes.tags.find(
            (tag) => tag.attributes.company_tag_id === tagId
        );

        if (isChecked) {
            setChecked((prevChecked) => [...prevChecked, tagId]);
            api.post(TAG_ASSOCIATION_URL, {
                company_id: company.attributes.company_id,
                company_tag_id: tagId,
            });
        } else {
            setChecked((prevChecked) => prevChecked.filter((id) => id !== tagId));

            if (association) {
                console.log(association.id)
                api.delete(`${TAG_ASSOCIATION_URL}/${association.id}`);
            }
        }
    };

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>Tags</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    {tagsList.map((tag, index) => (
                        <Form.Check
                            key={tag.id}
                            type="checkbox"
                            label={tag.attributes.company_tag}
                            checked={checked.includes(tag.id)}
                            onChange={(e) =>
                                handleCheckboxChange(tag.id, e.target.checked)
                            }
                        />
                    ))}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TagModal;
