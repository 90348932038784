import React from 'react';
import Table from 'react-bootstrap/Table';
import '../../../styles/home.css'

const Inventory = ({ darkMode }) => {
    return (
        <div >
            <h2 >Inventory</h2>
            <Table striped bordered hover responsive variant={darkMode ? 'dark' : ''}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Location</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Wheat</td>
                        <td>100</td>
                        <td>Bin A</td>
                        <td>Dry</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default Inventory;
