import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import api from '../../services/ApiConfig';
import validator from 'validator';

const AddressModal = ({ show, hide, editMode, company, address }) => {
    const [newAddress, setNewAddress] = useState({
        address_name: '',
        address_attribute: '',
        address1: '',
        address2: '',
        town: '',
        country_code: '',
        province_code: '',
        postal_code: '',
        isPrimary: false,
    });


    const [errors, setErrors] = useState({
        address_name: '',
        address_attribute: '',
        address1: '',
        address2: '',
        town: '',
        country_code: '',
        province_code: '',
        postal_code: '',
    });

    const ADDRESSES_URL = '/addresses';

    useEffect(() => {
        if (editMode) {
            // Set initial address values if in edit mode
            setNewAddress({
                address_name: address.attributes.address_name || '',
                address_attribute: address.attributes.address_attribute || '',
                address1: address.attributes.address1 || '',
                address2: address.attributes.address2 || '',
                town: address.attributes.town || '',
                country_code: address.attributes.country_code || '',
                province_code: address.attributes.province_code || '',
                postal_code: address.attributes.postal_code || '',
                isPrimary: address.attributes.isPrimary || '',
            });
        } else {
            // Reset form if in add mode
            setNewAddress({
                address_name: '',
                address_attribute: '',
                address1: '',
                address2: '',
                town: '',
                country_code: '',
                province_code: '',
                postal_code: '',
                isPrimary: false,
            });
        }
    }, []);

    const handleAddressChange = (event) => {
        setNewAddress({ ...newAddress, [event.target.name]: event.target.value });
    };

    const validateForm = () => {
        const newErrors = {
            address_name: '',
            address1: '',
            town: '',
            country_code: '',
            province_code: '',
            postal_code: '',
        };

        if (!validator.isLength(newAddress.address_name, { min: 1 })) {
            newErrors.address_name = 'Address name is required.';
        }
        if (!validator.isLength(newAddress.address1, { min: 1 })) {
            newErrors.address1 = 'Address 1 is required.';
        }
        if (!validator.isLength(newAddress.town, { min: 1 })) {
            newErrors.town = 'Town is required.';
        }

        if (!validator.isLength(newAddress.address_attribute, { min: 1 })) {
            newErrors.address_attribute = 'Address attribute is required.';
        }

        if (!validator.isLength(newAddress.country_code, { min: 1 })) {
            newErrors.country_code = 'Country is required.';
        }
        if (!validator.isLength(newAddress.province_code, { min: 1 })) {
            newErrors.province_code = 'Province is required.';
        }
        if (
            newAddress.country_code === 'CA' &&
            !validator.isPostalCode(newAddress.postal_code, 'CA')
        ) {
            newErrors.postal_code = 'Invalid Canadian postal code.';
        }
        if (
            newAddress.country_code === 'US' &&
            !validator.isPostalCode(newAddress.postal_code, 'US')
        ) {
            newErrors.postal_code = 'Invalid US ZIP code.';
        }

        setErrors(newErrors);

        // Return true if there are no errors, false otherwise
        return Object.values(newErrors).every((error) => error === '');
    };

    const handlePostAddress = async () => {
        try {
            const isValid = validateForm();

            if (company && isValid) {
                const addressWithCompanyId = {
                    ...newAddress,
                    company_id: company.attributes.company_id,
                };

                const res = await api.post(ADDRESSES_URL, addressWithCompanyId);
                hide();
            } else {
                console.error("Validation failed. Please check the required fields.");
                // Handle the case when no company is selected or there are validation errors
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handlePutAddress = async () => {
        try {
            const isValid = validateForm();

            if (isValid) {
                const res = await api.put(
                    `${ADDRESSES_URL}/${address.attributes.address_id}`,
                    newAddress
                );
                hide();
            } else {
                console.error("Validation failed. Please check the required fields.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleHide = () => {
        setErrors('');
        hide();
    }

    return (
        <Modal show={show} onHide={handleHide} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Add Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="m-3">
                    <h4>{editMode ? 'Edit Address' : 'Add Address'}</h4>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Address Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter address name"
                                    value={newAddress.address_name}
                                    onChange={(e) => setNewAddress({ ...newAddress, address_name: e.target.value })}
                                    isInvalid={!!errors.address_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address_name}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Address Type</Form.Label>
                                <Form.Control

                                    as="select"
                                    value={newAddress.address_attribute}
                                    onChange={(e) => setNewAddress({ ...newAddress, address_attribute: e.target.value })}
                                    isInvalid={!!errors.address_attribute}

                                >
                                    <option value="">Select Address Type</option>
                                    <option value="billing">Billing</option>
                                    <option value="physical">Physical</option>
                                    <option value="pickup">Pick-up</option>
                                    <option value="delivery">Delivery</option>
                                    <option value="elevator">Elevator</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.address_attribute}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Address 1</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address 1"
                                    value={newAddress.address1}
                                    onChange={(e) => setNewAddress({ ...newAddress, address1: e.target.value })}
                                    isInvalid={!!errors.address1}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.address1}
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Address 2</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address 2"
                                    value={newAddress.address2}
                                    onChange={(e) => setNewAddress({ ...newAddress, address2: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group>
                                <Form.Label>Town</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Town"
                                    value={newAddress.town}
                                    onChange={(e) => setNewAddress({ ...newAddress, town: e.target.value })}
                                    isInvalid={!!errors.town}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.town}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={newAddress.country_code}
                                    onChange={(e) => setNewAddress({ ...newAddress, country_code: e.target.value })}
                                    isInvalid={!!errors.country_code}
                                >
                                    <option value="">Select Country</option>
                                    <option value="CA">Canada</option>
                                    <option value="US">USA</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {errors.country_code}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            {newAddress.country_code === "CA" ? (
                                <Form.Group>
                                    <Form.Label>Province</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={newAddress.province_code}
                                        onChange={(e) => setNewAddress({ ...newAddress, province_code: e.target.value })}
                                        inInvalid={!!errors.province_code}
                                    >
                                        <option value="">Select Province</option>
                                        <option value="ON">Ontario</option>
                                        <option value="QB">Quebec</option>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">British Columbia</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">Newfoundland and Labrador</option>
                                        <option value="NS">Nova Scotia</option>
                                        <option value="PE">Prince Edward Island</option>
                                        <option value="SK">Saskatchewan</option>
                                        <option value="NT">Northwest Territories</option>
                                        <option value="NU">Nunavut</option>
                                        <option value="YT">Yukon</option>

                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.province_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : (
                                <Form.Group>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={newAddress.province_code}
                                        onChange={(e) => setNewAddress({ ...newAddress, province_code: e.target.value })}
                                        inInvalid={!!errors.province_code}
                                    >
                                        <option value="">Select State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>

                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.province_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Col>
                        <Col>
                            {newAddress.country_code === "CA" ? (
                                <Form.Group>
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter postal code"
                                        value={newAddress.postal_code}
                                        onChange={(e) => setNewAddress({ ...newAddress, postal_code: e.target.value })}
                                        isInvalid={!!errors.postal_code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.postal_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : (
                                <Form.Group>
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter zip code"
                                        value={newAddress.postal_code}
                                        onChange={(e) => setNewAddress({ ...newAddress, postal_code: e.target.value })}
                                        isInvalid={!!errors.postal_code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.postal_code}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Primary Address"
                                    checked={newAddress.isPrimary}
                                    onChange={(e) => setNewAddress({ ...newAddress, isPrimary: e.target.checked })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                    Close
                </Button>
                <Button variant="primary" onClick={editMode ? handlePutAddress : handlePostAddress}>
                    {editMode ? 'Save Address' : 'Add Address'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddressModal;
