import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faFileContract, faNewspaper, faRobot, faUserCheck, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col } from 'react-bootstrap';

const QuickLinks = ({ darkMode }) => {
    const quickLinksData = [
        { to: '/tickets', icon: faClipboardList, text: 'Tickets' },
        { to: '/contracts', icon: faFileContract, text: 'Contracts' },
        { to: '/news', icon: faNewspaper, text: 'News' },

    ];

    return (
        <div className={`quick-links`}>
            <h3>Quick Links</h3>
            <Row className="text-center">
                {quickLinksData.map((link, index) => (
                    <Col md={4} key={index}>
                        <Link to={link.to} style={{ textDecoration: 'none', color: 'black' }}>
                            <Card className={`${darkMode ? 'dark-mode-accent' : ''}`}>
                                <Card.Body>
                                    <FontAwesomeIcon icon={link.icon} size="3x" color='var(--harvyst-green)' />
                                    <p>{link.text}</p>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default QuickLinks;
