import React, { useState, useEffect, useCallback } from 'react';
import {Link} from 'react-router-dom';
import  Card  from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import CardGroup from 'react-bootstrap/CardGroup';
import { useDataContext } from '../../services/DataContext';

import "../../styles/ticketboard.css";

const styles = {
    card: {
      backgroundColor: '#B7E0F2',
      borderRadius: 55,
      padding: '3rem'
    },
    cardImage: {
      objectFit: 'cover',
      height: 175
      //borderRadius: 55
    }
  }

const TicketBoard = () => {

    const URL = "wss://" + window.location.hostname + '/harvyst_ws'
//    const URL = "ws://" + window.location.hostname + '/harvyst_ws'
    //const URL = "ws://127.0.0.1:9505";
    const [rawTickets, setRawTickets] = useState([]);
    const [tickets, setTickets] = useState([])
    const [serverMessage, setServerMessage] = useState();
    const [shouldReconnect, setShouldReconnect] = useState(false);
//    const [webSocketReady, setWebSocketReady] = useState(false);
//    const [webSocket, setWebSocket] = useState(new WebSocket(URL));
    const { commodity_data, company_data } = useDataContext();
    

    // WEBSOCKET
    const connectWebSocket = useCallback(() => {

        const webSocket = new WebSocket(URL);

        webSocket.onopen = (event) => {
            console.log('WS: onOpen()');
            setShouldReconnect(false);
        };
    
        webSocket.onmessage = function (event) {
            console.log('WS: onMessage()')
            if(typeof event === 'undefined'){
                return;
            }
            if(event && event.data){
                console.log('What an event !', event)
                setServerMessage(JSON.parse(event.data));
            }
        };
    
        webSocket.onclose = function (event) {
            console.log("WS: onClose()")                 
            setTimeout(() => {
                setShouldReconnect(true);
            }, 5000);
        };
    
        webSocket.onerror = function (err) {
          console.error('Socket encountered error: ', err.message, 'Closing socket');
          if(webSocket !== 'undefined'){
            webSocket.close();
          }
          setShouldReconnect(true);
        };
    
        return () => { webSocket.close();};
    }, []);
    useEffect(() => {
        return connectWebSocket();
    }, [connectWebSocket, shouldReconnect]);

    useEffect(() => {
        if(serverMessage && serverMessage != '' && !serverMessage.hasOwnProperty('action_heartbeat')){
            console.log("Message from server ", serverMessage.id)
            let newTickets = [...rawTickets];

            const idx = rawTickets?rawTickets.findIndex(t => t.id == serverMessage.id):-1;   

            // Normal messages
            if((serverMessage.attributes.transaction_type === 'hold') || 
               (serverMessage.attributes.transaction_type == null)){
                if(idx < 0){
                    serverMessage.status = 'Checking in';
                    newTickets = [...rawTickets, serverMessage];
                }else{
                    serverMessage.status = getNewStatus(newTickets[idx].attributes, serverMessage.attributes, newTickets[idx].status);

                    newTickets[idx] = serverMessage;                   
                }
                setRawTickets(newTickets);
            }else if(idx >= 0){
                if(serverMessage.attributes.is_deleted || serverMessage.attributes.is_complete){//} || serverMessage.attributes.transaction_type != 'hold'){
                    newTickets.splice(idx, 1);//delete newTickets[idx];
                    setRawTickets(newTickets);
                }
            }
        }
    }, [serverMessage]);


    const getNewStatus = (oldMessage, newMessage, defaultStatus) =>{
        if((oldMessage.gross_weight != newMessage.gross_weight) && (newMessage.gross_weight != 0)){
            return 'Weighing ...';
        }
        if((oldMessage.tare_weight != newMessage.tare_weight) && (newMessage.tare_weight != 0)){
            return 'Weighing ...';
        }
        if((oldMessage.gross_image_token != newMessage.gross_image_token) && (newMessage.gross_image_token != null)){
            return 'Picture ...';
        }
        if((oldMessage.dockage_pct != newMessage.dockage_pct) && (newMessage.dockage_pct != 0)){
            return 'Dockage ...';
        }
        if((oldMessage.moisture_pct != newMessage.moisture_pct) && (newMessage.moisture_pct != 0)){
            return 'Moisture ...';
        }
        if((oldMessage.tare_image_token != newMessage.tare_image_token) && (newMessage.tare_image_token != null)){
            return 'Picture ...';
        }
        return defaultStatus;
    }    

    // Fetch news entries from the Flask API when the component mounts
    useEffect(() => {
        try {
            const formattedTickets = rawTickets.map((rawTicket) => {
                const url = 'https://' + window.location.hostname + '/api/v1/images/get/';
//                const url = 'http://' + window.location.hostname + '/api/v1/images/get/';

                const attributes = rawTicket.attributes;
                const commodity = commodity_data.find((c) => c.commodity_id === attributes.commodity_id);
                const company = company_data.find((c) => c.company_id === attributes.company_id);
                console.log('All companies ', company_data)
                let image_url = '';
                if(attributes.tare_image_token != null){
                    image_url = url + attributes.tare_image_token;
                }else if(attributes.gross_image_token != null){
                    image_url = url + attributes.gross_image_token;                    
                }else if(company && company.hasOwnProperty('company_image') && company.company_image != null){
                    image_url = url + company.company_image;
                }else{
                    image_url = url + 'blank_avatar1';
                }


                return {
                    ticket_id : rawTicket.id,
                    ticketNumber: ((attributes.scaleticket == null) || (attributes.scaleticket == '')) ? attributes.ticket_id: attributes.scaleticket,
                    transactionType: attributes.transaction_type,
                    grossWeight : attributes.gross_weight?attributes.gross_weight:0,
                    tareWeight : attributes.tare_weight?attributes.tare_weight:0,
                    netWeight : attributes.net_weight?attributes.net_weight:0,      
                    moisturePct : attributes.moisture_pct?attributes.moisture_pct:0.0,
                    dockagePct : attributes.dockage_pct?attributes.dockage_pct:0.0,
                    companyName: company ? company.company_name : '',
                    commodity: commodity ? commodity.commodity : '',
                    comments: attributes.comments,
                    date: attributes.ticket_dt,
                    status: rawTicket.status,
                    image_url: image_url,
                    createdTS : (new Date(attributes.created_ts+'Z')).toLocaleString('en-CA'),
                };
            });

            setTickets(formattedTickets);
        } catch (error) {
            console.error('Error processing tickets:', error);
        }
    }, [rawTickets]);

    return (
        <div id='ticketboard-div'>
        <CardGroup>
        {tickets.map((t) => (
        <Link key={t.ticket_id} style={{ color: 'inherit', textDecoration: 'inherit' }} to={'/new-ticket/'+t.ticket_id}>

        <Card  style={{ width: '18rem' }}>
        <Card.Header>{t.status}</Card.Header>
        <Card.Header>{t.createdTS}</Card.Header>
        <Card.Img variant="top" src={t.image_url} style={styles.cardImage}/>
        <Card.Body>
            <Card.Title>{t.companyName}</Card.Title>
            <Card.Text>
                <span className='card-val'>{t.commodity}</span> {t.netWeight == 0?null:'( ' +t.netWeight.toLocaleString('en-CA')+' KG )'}
            </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
            <ListGroup.Item style={{ backgroundColor: (t.ticket_id != t.ticketNumber) ? '#dddddd' : '#ffFFFF' }}><span className='card-lbl'>TICKET:</span> <span className='card-val'>{t.ticketNumber}</span></ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: (t.grossWeight > 0) ? '#dddddd' : '#ffFFFF' }}><span className='card-lbl'>IN:</span> <span className='card-val'>{t.grossWeight.toLocaleString('en-CA')}KG</span></ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: (t.tareWeight > 0) ? '#dddddd' : '#ffFFFF' }}><span className='card-lbl'>OUT:</span> <span className='card-val'>{t.tareWeight.toLocaleString('en-CA')}KG</span></ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: (t.dockagePct > 0 && t.moisturePct > 0) ? '#dddddd' : '#ffffff' }}><span className='card-lbl'>MOIST:</span> <span className='card-val'>{t.moisturePct.toLocaleString('en-CA')}</span>  <span className='card-lbl'>DOCK:</span> <span className='card-val'>{t.dockagePct.toLocaleString('en-CA')}</span></ListGroup.Item>
            {(t.comments != '') && <ListGroup.Item ><span className='card-val-comments'>{ t.comments }</span></ListGroup.Item>}
            
        </ListGroup>
        <Card.Body>
            <Card.Link href="#">Send Message</Card.Link>
        </Card.Body>
    </Card>
    </Link>

    ))}
        </CardGroup>
        </div>

    );
};
export default TicketBoard;
