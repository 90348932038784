import React from 'react';
import { Table, Modal } from 'react-bootstrap';

const SubscribersModal = ({ show, onHide, subscribers }) => {
    return (
        <Modal
            show={show} onHide={onHide} backdrop="static" keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Subscriber List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subscribers.map((subscriber, index) => (
                            <tr key={index}>
                                <td>{subscriber.name}</td>
                                <td>{subscriber.email}</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </Modal.Body>
        </Modal>
    );
};

export default SubscribersModal;