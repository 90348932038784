import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from './firebase';

const UserContext = createContext('');

const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState('');
    //const [settings, setSettings] = useState({darkMode:false});
    const navigate = useNavigate();

    

    const createUser = (email, password) => {
        console.log('about to firebase');
        return createUserWithEmailAndPassword(auth, email, password);
    };
    
    const sendVerificationEmail = (user) => {
        return sendEmailVerification(user);
    }

    const signIn = (email, password) =>{
        return signInWithEmailAndPassword(auth, email, password);
    };
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }
    
    const userLoggedIn = () => {
        return user.hasOwnProperty('emailVerified') && user.emailVerified;
    }

    const logout = () =>{
        // ugg.  Making this signOut conflicts with firebase.signOut 
        // causing an infinite loop
        return signOut(auth);
    }

    useEffect(()=>{
        console.log('AuthContext useEffect()');
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if(currentUser){
                console.log('onAuthStateChange()');
                let bRedirect = false;
                console.log('Current User ', currentUser);
                if(!user.hasOwnProperty('email') && currentUser.hasOwnProperty('email')){
                    bRedirect = true;
                }
                setUser(currentUser);
                currentUser.getIdToken().then(function(idToken) { setToken(idToken); }).catch(function(error){console.error('Error getting ID token ', error);});
                console.log('Redirecting ... ', bRedirect)
                if(bRedirect){
                    navigate('/home');
                }
            }else{
                setUser({});
                setToken('');        
            }
        });

        return () => {
            unsubscribe();
        };

    }, []);
    return(
        <UserContext.Provider value={{createUser, signIn, logout, userLoggedIn, token, user }}>
            {children}
        </UserContext.Provider>
    )
};


const useAuthContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useAuthContext must be used within a DataProvider');
    }
    return context;
};

export { AuthContextProvider, useAuthContext };
