import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { app } from '../../services/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [resetSent, setResetSent] = useState(false);
    const [error, setError] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();

        try {
            await sendPasswordResetEmail(app.auth(), email);
            setResetSent(true);
            setError('');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="login-container">
            <video autoPlay muted loop className="background-video">
                <source src="https://storage.googleapis.com/harvyst-public/1058446675-preview.mp4" type="video/mp4" />
            </video>
            <Container>
                <Row className="justify-content-center align-items-center login-box">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <h1 className="text-center mb-4"><img src={process.env.PUBLIC_URL + "/images/logos/harvyst.svg"} alt="Harvyst logo" className="img-fluid mb-3" width={200} height={35} /></h1>
                        <h2>Forgot Password</h2>
                        {resetSent ? (
                            <p>Reset link sent to your email. Check your inbox.</p>
                        ) : (
                            <Form onSubmit={handleResetPassword}>
                                {error && <p className="text-danger">{error}</p>}
                                <Form.Group controlId="formEmail" className="my-2">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" block className="mx-1">
                                    Send Reset Link
                                </Button>

                            </Form>

                        )}
                        <Button variant="secondary" type="button" block className="mx-1 my-2 harvyst-green" href="/login">  <FontAwesomeIcon icon={faArrowLeft} /> Back to Login </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ForgotPassword;
