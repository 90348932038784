import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className="container text-center mt-5">
            <img src="/images/logos/harvyst.svg" alt="Harvey Logo" className="mb-5" width={250} />
            <h1 className="mb-3">Oops! Looks like something is wrong.</h1>

            <p className="mb-4">You have landed on a page that doesn't exist. Contract <a href='mailto:support@harvyst.ag'>support@harvyst.ag</a> if this continues.</p>
            <p>
                <Link to='/' className="btn btn-primary">Go back to the home page</Link>.
            </p>
            <h4 className="mb-3 text-muted">Error: 404 Route Not Found</h4>
        </div>
    );
}

export default NotFound;
