import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/ApiConfig';
import { useDataContext } from '../../services/DataContext';



function Settings({ darkMode, darkModeToggle }) {

    const [panels, setPanels] = useState([]);
    const [unit, setUnit] = useState();
    const activePanels = panels.filter(panel => panel.enabled);
    const inactivePanels = panels.filter(panel => !panel.enabled);
    const { reloadDataModel, reloadUserSettings, userSettings } = useDataContext();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/usersettings');

                if (response.status === 200) {
                    const data = await response.data;
                    const unitSetting = data.data.find(item => item.attributes.key === 'weight_unit');

                    if (unitSetting) {
                        setUnit(unitSetting.attributes.value);
                    }

                    const panelData = data.data.filter(item => item.attributes.key.includes('_panel'));
                    panelData.sort((a, b) => a.attributes.value - b.attributes.value);
                    console.log(panelData);
                    // set the panel array to the panel data. The order is equal to the value of the panel, if the panel is 0 then it will be disabled. 

                    setPanels(panelData.map(panel => {
                        return {
                            id: panel.attributes.key,
                            enabled: panel.attributes.value !== '0',
                            order: panel.attributes.value
                        };
                    }
                    ));

                } else {
                    console.error('Failed to fetch settings. Settings.js');
                }
            } catch (error) {
                // Handle network or other errors
                console.error('Network error:', error);
            }
        };

        fetchData();
    }, []);


    const handleUnitChange = (newUnit) => {
        api.put('/usersettings/weight_unit', { value: newUnit.toString() });
        setUnit(newUnit);

    };

    const handleDarkModeChange = () => {
        darkModeToggle();
    };


    const handleToggleEnabled = async (id) => {
        const updatedPanels = panels.map((panel) => {
            if (panel.id === id) {
                return { ...panel, enabled: true };
            }
            return panel;
        });
        setPanels(updatedPanels);

        try {
            // Send the updated order to the API as '1' for enabled panels
            await api.put(`/usersettings/${id}`, { value: '1' });

            // If the panel was previously hidden, update its order to place it at the end
            const hiddenPanel = inactivePanels.find((panel) => panel.id === id);
            if (hiddenPanel) {
                await api.put(`/usersettings/${id}`, { value: (activePanels.length + 1).toString() });
            }
        } catch (error) {
            console.error('Failed to update panel visibility:', error);
        }
    };


    const handleToggleDisable = async (id) => {
        const updatedPanels = panels.map((panel) => {
            if (panel.id === id) {
                return { ...panel, enabled: false };
            }
            return panel;
        });
        setPanels(updatedPanels);

        try {
            await api.put(`/usersettings/${id}`, { value: '0' });
        } catch (error) {
            console.error('Failed to update panel visibility:', error);
        }
    };
    const updatePanelOrderInAPI = async () => {
        try {
            await Promise.all(panels.map(panel => api.put(`/usersettings/${panel.id}`, { value: panel.order.toString() })));
        } catch (error) {
            console.error('Failed to update panel order:', error);
        }
    };

    const reorderPanel = (id, newIndex) => {
        const updatedPanels = [...panels];
        const currentIndex = updatedPanels.findIndex(panel => panel.id === id);

        if (currentIndex !== -1) {
            const movedPanel = updatedPanels[currentIndex];
            updatedPanels.splice(currentIndex, 1); // Remove the moved panel
            updatedPanels.splice(newIndex, 0, movedPanel); // Insert the moved panel at the new index

            // Update the order values for all panels based on their positions in the array
            updatedPanels.forEach((panel, index) => {
                panel.order = index + 1;
            });

            setPanels(updatedPanels);
            updatePanelOrderInAPI();
        }
    };

    const handleMoveUp = id => {
        const index = panels.findIndex(setting => setting.id === id);
        if (index > 0) {
            reorderPanel(id, index - 1);
        }
    };

    const handleMoveDown = id => {
        const index = panels.findIndex(setting => setting.id === id);
        if (index < panels.length - 1) {
            reorderPanel(id, index + 1);
        }
    };

    return (
        <div className="">
            <h1>Settings</h1>
            <h3 className='mt-3'>Preferences</h3>
            <Form>
                <Form.Group>
                    <Form.Label>Weight Unit Preference</Form.Label>
                    <div>
                        <Form.Check
                            type="radio"
                            label="Tonnes (T.)"
                            name="unitRadio"
                            id="tonnesRadio"
                            checked={unit === 'Tonnes'}
                            onChange={() => handleUnitChange('Tonnes')}
                        />
                        <Form.Check
                            type="radio"
                            label="Bushels (Bu.)"
                            name="unitRadio"
                            id="bushelsRadio"
                            checked={unit === 'Bushels'}
                            onChange={() => handleUnitChange('Bushels')}
                        />
                    </div>
                </Form.Group>
            </Form>
            <h3 className='mt-3'>Appearance</h3>
            <Form>
                <Form.Group>
                    <Form.Check
                        type="switch"
                        label="Dark Mode"
                        checked={darkMode}
                        onChange={handleDarkModeChange}
                    />
                </Form.Group>
            </Form>
            <Button className="harvyst-green"  onClick={() => {reloadDataModel(); reloadUserSettings();console.log('USERS:', userSettings);} }>
                Reload Data Model
            </Button>

            <h3 className='mt-3'>Homepage Panels</h3>
            <div className="row">
                <div className="col-md-6">
                    <h4>Displayed Panels</h4>
                    <ListGroup>
                        {activePanels.map(setting => (
                            <ListGroup.Item key={setting.id} className={`d-flex justify-content-between ${darkMode ? 'dark-mode' : ''}`}
                            >
                                {setting.id}

                                <div className=''>
                                    <Button variant="secondary" className='mx-1' size="sm" onClick={() => handleMoveUp(setting.id)}><FontAwesomeIcon icon={faArrowUp} /> Up</Button>
                                    <Button variant="secondary" size="sm" className='mx-1' onClick={() => handleMoveDown(setting.id)}><FontAwesomeIcon icon={faArrowDown} /> Down</Button>


                                    <Button variant="danger" size="sm" className='mx-3' onClick={() => handleToggleDisable(setting.id)}>Hide</Button>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                <div className="col-md-6">
                    <h4>Hidden Panels</h4>
                    <ListGroup >
                        {inactivePanels.map(setting => (
                            <ListGroup.Item key={setting.id} className={`d-flex justify-content-between ${darkMode ? 'dark-mode' : ''}`}
                            >
                                <div>
                                    <span className={darkMode ? 'dark-mode' : 'text-muted'}>{setting.id}</span>
                                    <Button variant="primary" size="sm" className='mx-2' onClick={() => handleToggleEnabled(setting.id)}>Show</Button>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            </div>
        </div >
    );
}

export default Settings;
