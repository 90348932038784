// Firebase configuration
import { initializeApp }  from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCpuQmqc7M8S1RvwViE39BDBlgf1zjUcjM",
    authDomain: "harvyst.firebaseapp.com",
    databaseURL: "https://harvyst.firebaseio.com",
    projectId: "harvyst",
    storageBucket: "harvyst.appspot.com",
    messagingSenderId: "680064863266",
    appId: "1:680064863266:web:e7e23a6e6b3aec482f8e7b"
};

export const app = initializeApp(firebaseConfig);  // Initialize Firebase
export const auth =  getAuth(app);
