import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import NotificationTray from './NotificationTray.js';
import '../styles/navbar.css';
import { useAuthContext } from '../services/AuthContext';

const AppNavbar = () => {
    const { user, settings, logout } = useAuthContext();
    //const darkMode = true; // use to be a  variant={darkMode ? 'dark' : 'light'} in the navbar object
    const navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notifications, setNotifications] = useState([{
        message: 'New message received',
        timestamp: '2 minutes ago',
        link: '#',
    },
    {
        message: 'Task assigned to you',
        timestamp: '1 hour ago',
        link: '#',
    },
    {
        message: 'Reminder: Meeting at 3 PM',
        timestamp: 'yesterday',
        link: '#',
    },]);


    const handleNotificationClick = (event) => {
        event.stopPropagation();
        setShowNotification(!showNotification);
    };

    const handleClickOutside = (event) => {
        setShowNotification(false);
    };


    const addNotification = (newNotification) => {

        setNotifications((notifications) => [...notifications, newNotification]);
        console.log(notifications);

    };

    const removeNotification = (index) => {
        const updatedNotifications = [...notifications];
        updatedNotifications.splice(index, 1);
        setNotifications(updatedNotifications);
    };

    const hasNotifications = notifications.length > 0;
    const location = useLocation();

    const handleSignOut = async () => {
        try {
            console.log('Signing out');
            //            signOut();
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <header >
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to="/"><img
                        src={process.env.PUBLIC_URL + "/images/logos/harvyst.svg"}
                        alt="Harvyst Logo"
                        width="150"
                        height="25"
                    />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className='ms-auto'>
                            <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text className='d-none d-lg-block'>Signed in as:</Navbar.Text>
                                <NavDropdown title={user && user.email} id="collasible-nav-dropdown" >
                                    <NavDropdown.Item as={Link} to="/account">
                                        Account
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/settings">
                                        Settings
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/manage">
                                        Company Manager
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item as={Link} to="/login" onClick={handleSignOut}>
                                        Logout
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link onClick={handleNotificationClick}>

                                    <FontAwesomeIcon className={hasNotifications ? 'notification-bell-active' : 'notification-bell'} icon={faBell} />
                                </Nav.Link>                                <NotificationTray show={showNotification} notifications={notifications} removeNotification={removeNotification} handleClose={handleClickOutside} />
                            </Navbar.Collapse>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar >
        </header >
    );
};

export default AppNavbar;
