import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const WeightOverwriteModal = ({ show, handleClose, weighTypeToConfirm, handleConfirmation }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to overwrite the {weighTypeToConfirm} weigh-in?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {
          handleClose();
          handleConfirmation();
        }}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WeightOverwriteModal;
