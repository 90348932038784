import React, { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addErrorToast = (message, title='Error') => {
        console.log('Toast ', message);
        const newToast = { id: new Date().getTime(), message, title };
        setToasts((prevToasts) => [...prevToasts, newToast]);
    };

    const removeToast = (id) => {
        console.log('Toast remove')
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ toasts, addErrorToast, removeToast }}>
            {children}
        </ToastContext.Provider>
    );
};

const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

export { ToastProvider, useToast };
