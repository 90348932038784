import React, { useState, useEffect } from 'react';
import { Container, Row, Col, CardGroup } from 'react-bootstrap';
import HomepageSettings from '../settings/Settings';
import { app } from '../../services/firebase';
import InventoryPanel from './panels/InventoryPanel';
import LinksPanel from './panels/LinksPanel';
import MarketDataPanel from './panels/MarketStatsPanel';
import UpdatesPanel from './panels/UpdatesPanel';
import GreetingsPanel from './panels/GreetingsPanel';
import api from '../../services/ApiConfig'; // Import your API configuration

import { useAsyncError } from 'react-router-dom';

function Home() {


/*
    const [userSettings, setUserSettings] = useState([]);
    const [enabledPanels, setEnabledPanels] = useState([]);

useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    */
   useEffect(() => {
    console.log('useEffect home.js');
    //console.log(user && user.email);

   }, []);
/*
    useEffect(() => {
        console.log('useEffect (home.js)')
        // Fetch user settings from the API
        const fetchData = async () => {
            try {
                const response = await api.get('/usersettings');

                if (response.status === 200) {
                    const data = await response.data;
                    setUserSettings(data.data);
                } else {
                    console.error('Failed to fetch user settings');
                }
            } catch (error) {
                console.error('Error fetching user settings:', error);
            }
        };

        fetchData();
    }, []);
*/
    // useEffect(() => {
    //     // Filter and sort the panels based on user settings
    //     const sortedPanels = userSettings
    //         .filter((setting) => setting.attributes.key.endsWith('_panel') && setting.attributes.value !== "0")
    //         .sort((a, b) => a.attributes.value - b.attributes.value);

    //     setEnabledPanels(sortedPanels);
    // }, [userSettings]);

    return (
        <div >
            <CardGroup>
                <GreetingsPanel/>
                <UpdatesPanel/>        
            </CardGroup>
        </div>
        

    );
}

export default Home;
