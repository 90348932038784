import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import validator from 'validator';
import api from '../../services/ApiConfig';

function CompanyModal({ show, hide, editMode, companyToEdit, tags }) {
    const [company, setCompany] = useState({
        company_name: '',
        cusno: '',
    });
    const [errors, setErrors] = useState('');
    const [networkErrors, setNetworkErrors] = useState('');

    const COMPANIES_URL = '/companies';

    useEffect(() => {

        console.log(company)

        if (editMode) {
            setCompany(
                {
                    company_name: companyToEdit.attributes.company_name,
                    cusno: companyToEdit.attributes.cusno,
                }

            );
        }
        else {
            
            setCompany({
                company_name: '',
                cusno: '',
            });
        }

    }, []);

    const validateForm = () => {
        let isValid = true;
        setErrors('');

        if (validator.isEmpty(company.company_name)) {
            setErrors('Company name cannot be empty.');
            isValid = false;
        }

        if (!validator.isLength(company.cusno, { max: 6 })) {
            setErrors('Cusno cannot have more than 6 characters.');
            isValid = false;
        }

        return isValid;
    };

    const handlePostCompany = async () => {
        if (validateForm()) {
            try {
                const res = await api.post(COMPANIES_URL, company);
                hide();
            } catch (error) {
                console.log(error);
                setNetworkErrors("There was an network error adding the company. Please try again.");
            }
        }
    }

    const handlePutCompany = async () => {
        if (validateForm()) {
            try {
                const res = await api.put(`${COMPANIES_URL}/${companyToEdit.attributes.company_id}`, company);
                hide();
            } catch (error) {
                console.log(error);
                setNetworkErrors("There was an network error adding the company. Please try again.");

            }
        }
    }

    const hideModal = () => {
        hide();
        setCompany({
            company_name: '',
            cusno: '',
        });
        setErrors('');
    }

    return (
        <Modal show={show} onHide={hideModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{editMode === true ? 'Edit Company' : 'Add Company'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <div className="d-flex justify-content-center">
                        <span className='text-danger'>{networkErrors}</span>
                    </div>
                    <Row>
                        <Col className="d-flex ">
                            <Form.Group className="m-3">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter company name"
                                    value={company.company_name}
                                    onChange={(e) => setCompany({ ...company, company_name: e.target.value })}
                                    isInvalid={errors && validator.isEmpty(company.company_name)}
                                />
                                <Form.Control.Feedback type="invalid">Company name cannot be empty.</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="m-3">
                                <Form.Label>Cusno</Form.Label>
                                <OverlayTrigger
                                    placement="top-start"
                                    overlay={<Tooltip>An easily recognizable name or character combination that identifies the company.</Tooltip>}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter cusno name"
                                        value={company.cusno}
                                        onChange={(e) => setCompany({ ...company, cusno: e.target.value })}
                                        isInvalid={errors && !validator.isLength(company.cusno, { max: 6 })}
                                    />
                                </OverlayTrigger>
                                <Form.Control.Feedback type="invalid">Cusno cannot have more than 6 characters.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Row>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hideModal}>
                    Close
                </Button>
                <Button className='harvyst-green' onClick={editMode ? handlePutCompany : handlePostCompany}>
                    {editMode ? 'Save Changes' : 'Add Company'}

                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default CompanyModal;