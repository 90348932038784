import React, { useState, useEffect } from 'react';
import { Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import '../../styles/news.css';
import NewsEntry from './NewsEntry';
import CreateNewsModal from './CreateNewsModal';
import EditNewsModal from './EditNewsModal';
import SubscribersModal from './SubscriberModal';
// import api from 'api';
import api from '../../services/ApiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../components/LoadingSpinner';

const News = ({ darkMode }) => {

    const URL = '/news'

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [newsEntries, setNewsEntries] = useState([]);
    const [editModalData, setEditModalData] = useState(null);
    // const [showSubscribersModal, setShowSubscribersModal] = useState(false);
    // const [subscribers] = useState([{ name: 'John Doe', email: 'john.doe@example.com' },
    // { name: 'Jane Smith', email: 'jane.smith@example.com' },
    // ]);

    // Function to fetch news entries from the Flask API
    const fetchNewsEntries = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(URL);

            if (Array.isArray(response.data.data)) {
                const formattedNewsEntries = response.data.data.map((entry) => ({
                    news_id: entry.attributes.news_id,
                    title: entry.attributes.title,
                    body: entry.attributes.body,
                    active: entry.attributes.active,
                    isArchived: entry.attributes.archived,
                    createdStamp: entry.attributes.created_ts,
                    modifiedStamp: entry.attributes.modified_ts,
                }));
                setNewsEntries(formattedNewsEntries);
            } else {
                setNewsEntries([]);
            }
        } catch (error) {
            console.error('Error fetching news entries:', error);
        }
        setIsLoading(false);
    };

    // Function to create a new news entry using the Flask API
    const createNewsEntry = async (title, body, active) => {
        const requestData = {
            title: title,
            body: body,
            active: active,

        };

        try {
            await api.post(URL, requestData);
            return fetchNewsEntries();
        } catch (error) {
            console.error('Error creating news entry:', error);
            throw error;
        }

    };

    // Function to update an existing news entry using the Flask API
    const updateNewsEntry = async (editedNews) => {
        const requestData = {
            title: editedNews.title,
            body: editedNews.body,
            active: editedNews.active,
        };

        try {
            await api.put(`${URL}/${editedNews.news_id}`, requestData);
            return fetchNewsEntries();
        } catch (error) {
            console.error('Error updating news entry:', error);
            throw error;
        }
    };

    // Function to delete a news entry using the Flask API
    const deleteNewsEntry = async (news) => {
        return api.delete(`${URL}/${news.news_id}`)
            .then(() => {
                return fetchNewsEntries();
            })
            .catch((error) => {
                console.error('Error deleting news entry:', error);
                throw error;
            });
    };

    // Function to handle toggling the isActive status of a news entry using the Flask API
    const toggleactive = async (news) => {
        const requestData = {
            title: news.title,
            body: news.body,
            active: !news.active,

        };

        try {
            await api.put(`${URL}/${news.news_id}`, requestData);
            return fetchNewsEntries();
        } catch (error) {
            console.error('Error updating news entry:', error);
            throw error;
        }
    };

    // Function to handle toggling the archived status of a news entry using the Flask API
    const toggleAchievedStatus = async (news) => {
        const requestData = {
            title: news.title,
            body: news.body,
            active: news.active,
            archived: !news.archived,
        };

        try {
            await api.put(`${URL}/${news.news_id}`, requestData);
            return fetchNewsEntries();
        } catch (error) {
            console.error('Error updating news entry:', error);
            throw error;
        }
    };

    // Fetch news entries from the Flask API when the component mounts
    useEffect(() => {
        fetchNewsEntries();
    }, []);

    // const handleShowSubscribersModal = () => {
    //     setShowSubscribersModal(true);
    // };

    // Function to create a new news entry
    const handlePublishNews = async (newstitle, newsBody, active) => {
        try {
            const response = await createNewsEntry(newstitle, newsBody, active);
            if (response.status === 200) {
                setShowCreateModal(false);

            };
        } catch (error) {
            console.error('Error creating news entry:', error);
        }
        fetchNewsEntries();
    };

    const handleEditNews = (news) => {
        setEditModalData(news);
        setShowEditModal(true);
    };


    const handleSaveEditedNews = async (editedNews) => {
        try {
            await updateNewsEntry(editedNews);
            setShowEditModal(false);
        } catch (error) {
            console.error('Error updating news entry:', error);
        }
        fetchNewsEntries();
    };

    const handleDeleteNews = async (news) => {
        try {
            await deleteNewsEntry(news);
        } catch (error) {
            console.error('Error deleting news entry:', error);
        }
        fetchNewsEntries();
    };

    return (
        <div>
            <div className='mb-2'>
                <h1>News</h1>
                <h6 className={darkMode ? ' ' : 'text-muted'}>
                    Keep your subscribers informed with the latest news and updates.
                </h6>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-2">
                            <Button
                                className="harvyst-green"
                                onClick={() => setShowCreateModal(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Publish News
                            </Button>
                            {/* <Button
                                className=""
                                variant="secondary"
                                onClick={() => handleShowSubscribersModal(true)}
                            >
                                View Subscribers
                            </Button> */}
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={<Tooltip className='button-tooltip'>Archive will hide away items without deleting them</Tooltip>}
                            >
                                <Form.Check
                                    className=""
                                    type="switch"
                                    label="Show Archived"
                                    id="archive-switch"
                                    checked={showArchived}
                                    onChange={() => setShowArchived(!showArchived)}
                                />
                            </OverlayTrigger>
                        </div>

                    </div >
                </div >
            </div >
            <Table hover variant={darkMode ? 'dark' : ''}>
                <thead>
                    <tr>
                        <th className="col-md-4">Title</th>
                        <th className="col-md-5">Body</th>
                        <th className="col-md-1"></th>
                    </tr>
                </thead>

                <tbody>
                    {newsEntries.length === 0 ? (
                        <tr>
                            <td colSpan={4} className='text-center'>
                                {isLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    "No news yet!"
                                )}
                            </td>
                        </tr>
                    ) : (
                        newsEntries.map((news, index) => {

                            if (news.isArchived === false || (news.isArchived && showArchived)) {
                                return (
                                    <NewsEntry
                                        key={news.news_id}
                                        news={news}
                                        onEdit={handleEditNews}
                                        onDelete={handleDeleteNews}
                                        onSetActive={toggleactive}
                                        onSetArchive={toggleAchievedStatus}
                                    />
                                );
                            }
                            return null; // Exclude inactive news
                        })
                    )}
                </tbody>

            </Table>

            <CreateNewsModal
                show={showCreateModal}
                onHide={() => setShowCreateModal(false)}
                onPublish={handlePublishNews}
            />

            {
                editModalData && (
                    <EditNewsModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        news={editModalData}
                        onSave={handleSaveEditedNews}
                    />
                )
            }

            {/* <SubscribersModal
                show={showSubscribersModal}
                onHide={() => setShowSubscribersModal(false)}
                subscribers={subscribers}
            /> */}

        </div >
    );
};
export default News;