// api.js
import axios from 'axios';
import { auth } from './firebase';

// Create an Axios instance with a base URL
const api = axios.create({
    // If I am NOT retrieving the user interface from this same URL.  The I will get CORS errors
//    baseURL: 'http://'+window.location.hostname+'/api/v1',
    baseURL: 'https://'+window.location.hostname+'/api/v1',
});

// Add a request interceptor to include the Firebase token in headers
api.interceptors.request.use(
    async (config) => {
        console.log('API INTERCEPT ', config);
        // Cannot use the UserContext here .. .because (I think) "Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:"
        const user = auth.currentUser;
        if (user) {
            // Get the Firebase token
            const token = await user.getIdToken();
            console.log('TOKEN ', token)
            // Set the Authorization header with the token
            config.headers['Authorization'] = `Bearer ${token}`;
            config.headers.JasonTest = 'YoooHooo';
//            console.log(config.headers)
        }
        return config;
    },
    /*
    (config) =>{
            const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImQxNjg5NDE1ZWMyM2EzMzdlMmJiYWE1ZTNlNjhiNjZkYzk5MzY4ODQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaGFydnlzdCIsImF1ZCI6ImhhcnZ5c3QiLCJhdXRoX3RpbWUiOjE3MDQ0ODA2NDcsInVzZXJfaWQiOiJzMXpQR3puN24xUmNuSmRrd0ZZQ1dOVDBpWkkyIiwic3ViIjoiczF6UEd6bjduMVJjbkpka3dGWUNXTlQwaVpJMiIsImlhdCI6MTcwNDQ4MDY0NywiZXhwIjoxNzA0NDg0MjQ3LCJlbWFpbCI6Imphc29uMjJAamFiaWRlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImphc29uMjJAamFiaWRlLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.XaV2dQgP6rRiFiHgvwizFX0lZXmJOPXSwYTpxBngyBIZwwsH-oYk-zsEdZL7-PuY2piR4rbjvgvhvKtHYAGtDdlfKbHUlVqECqq5pKKhP-ONsph_PQA88QzA4mJff_2n_AbK6zPGI67X0kpjm35u5UzLksMrzP3DguR8Z00sN9q16cjQZLcjmMo5UKtpNQFgFf8EZdP_-XpG00Xw0t0I9z5qERqZYl4a3zB_dzgCuRbKeYz5teKFFnYFwbFw5DytRFGZ38BSclE6k-xn-POkhst750af6-qWq8Rzzq4gOYsgd65oTQxDRgZg7EuCIVRtqICHWWYYcOOcdB5ogSC8jg';

            console.log('TOKEN ', token);

            config.headers.common['Authorization'] = `Bearer ${token}`;
            config.headers.common['Jason'] = 'Test';
            return config;
        
    },
    */
   /*
    (req) => {
        const user = auth.currentUser;
        if (user) {
            //const token = await user.getIdToken();
            const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImQxNjg5NDE1ZWMyM2EzMzdlMmJiYWE1ZTNlNjhiNjZkYzk5MzY4ODQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaGFydnlzdCIsImF1ZCI6ImhhcnZ5c3QiLCJhdXRoX3RpbWUiOjE3MDQ0ODA2NDcsInVzZXJfaWQiOiJzMXpQR3puN24xUmNuSmRrd0ZZQ1dOVDBpWkkyIiwic3ViIjoiczF6UEd6bjduMVJjbkpka3dGWUNXTlQwaVpJMiIsImlhdCI6MTcwNDQ4MDY0NywiZXhwIjoxNzA0NDg0MjQ3LCJlbWFpbCI6Imphc29uMjJAamFiaWRlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImphc29uMjJAamFiaWRlLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.XaV2dQgP6rRiFiHgvwizFX0lZXmJOPXSwYTpxBngyBIZwwsH-oYk-zsEdZL7-PuY2piR4rbjvgvhvKtHYAGtDdlfKbHUlVqECqq5pKKhP-ONsph_PQA88QzA4mJff_2n_AbK6zPGI67X0kpjm35u5UzLksMrzP3DguR8Z00sN9q16cjQZLcjmMo5UKtpNQFgFf8EZdP_-XpG00Xw0t0I9z5qERqZYl4a3zB_dzgCuRbKeYz5teKFFnYFwbFw5DytRFGZ38BSclE6k-xn-POkhst750af6-qWq8Rzzq4gOYsgd65oTQxDRgZg7EuCIVRtqICHWWYYcOOcdB5ogSC8jg';

            console.log('TOKEN ', token);

            req.headers['Authorization'] = `Bearer ${token}`
            req.headers.JasommmnTest = 'YoooHooo';
        }
        return req;

   },
   */
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
