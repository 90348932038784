import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const generateFakeData = (numDataPoints, timeInterval) => {
    const fakeData = [];
    const currentTime = new Date().getTime();

    for (let i = 0; i < numDataPoints; i++) {
        const timestamp = currentTime - i * timeInterval;
        fakeData.push({
            timestamp,
            value: Math.random() * 100,
        });
    }

    return fakeData.reverse();
};


const Chart = ({ chartOptions, removeChart }) => {
    const { symbol, timeIntervalValue } = chartOptions;

    const [chartData, setChartData] = useState(null);

    // const fetchData = async () => {
    //   try {
    //     const response = await api.get(`/marketdata/${symbol}?interval=${timeIntervalValue}`);
    //     const data = await response.json();
    //     return data;
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //     return null;
    //   }
    // };

    const handleRemove = () => {
        removeChart()
    }

    const formatChartData = (data) => {
        if (!data) {
            return null;
        }

        return {
            options: {
                chart: {
                    id: symbol,
                    toolbar: {
                        show: true,
                    },
                    width: '100%',
                    height: 400,
                    padding: {
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM',
                            hour: 'HH:mm',
                        },
                    },
                    title: {
                        text: 'Time',
                    },
                },
                yaxis: {
                    title: {
                        text: 'Market Prices',
                    },
                    labels: {
                        formatter: function (value) {
                            return value.toFixed(2);
                        },
                    },
                },
                title: {
                    text: `Market Data for ${symbol}`,
                    align: 'center',
                    margin: 50,
                    padding: 10,
                    style: {
                        fontSize: '1.2em',
                    },
                },
            },
            series: [
                {
                    data: data.map((item) => ({
                        x: new Date(item.timestamp).getTime(),
                        y: item.value,
                    })),
                },
            ],
        };
    };



    useEffect(() => {

        // const fetchDataAndFormat = async () => {
        //   const data = await fetchData();
        //   const formattedData = formatChartData(data);
        //   setChartData(formattedData);
        // };

        const fakeData = generateFakeData(20, timeIntervalValue);
        const formattedData = formatChartData(fakeData);
        setChartData(formattedData);
    }, [symbol, timeIntervalValue]);

    return chartData ? <><ReactApexChart {...chartData} /><button onClick={handleRemove}>X</button></> : <div>Loading...</div>;
};

export default Chart;
