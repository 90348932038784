import React, { createContext, useContext, useEffect, useState } from 'react';

import Dexie, { Table } from 'dexie';
import api from '../services/ApiConfig';

// This means (of course) that AuthProvider MUST come above DataProvider in the tree!!!

import { useAuthContext } from '../services/AuthContext'


const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [commodity_data, setCommodityData] = useState([]);
    const [company_data, setCompanyData] = useState([]);
    const [dataModel, setDataModel] = useState(0);
    const [userSettings, setUserSettings] = useState({});
    
    const getV = (settings_arr, settings_key, default_value, value_type) =>{
        let x = settings_arr.find(s => s.key === settings_key);
        if(x){
            // we know its a string
            if(value_type === 'string'){
                return x.value;
            }
            if(value_type === 'boolean'){
                return (x.value.toLowerCase() === 'true');
            }
            if(value_type === 'number'){
                return Number(x.value);
            }
        }
        return default_value;        
    }

    // From this format (saved) [{key:c.attributes.key, value:c.attributes.value,}, {}, {}, ...]
    const settingsJSONtoObj = (jsonS) => {
        const us = {};
        us.darkMode = getV(jsonS, 'darkMode', false, 'boolean');
        return us;
    }
    const reloadUserSettings = () =>{
        const db = getDatabaseHandle()
        api.get('/usersettings')
        .then(function (response){
            //console.log('UserSettings THEN ', response.data);
            if(response.status == 200){
                const newusersettings = saveUserSettings(db, response.data.data);
                const us = settingsJSONtoObj(newusersettings);
                setUserSettings(us);
                console.log(us)
            }
        })
        .catch(function (error) {
            console.log('Error loading user settings ', error);
        });
    }
    const saveUserSettings = (db, usersets) => {
        const newusersets = usersets.map(c => ({key:c.attributes.key, value:c.attributes.value, }));

        db.dm_usersettings.bulkPut(newusersets).then(() => console.log('Saved new usersettings')).catch((error) => console.error('Error saving usersettings ', error));
        return newusersets;
    }

    const reloadDataModel = () => {
        /*
        const { userLoggedIn } = useAuthContext();

        if(!userLoggedIn()){
            return;
        }
        console.log('Reloading Data model')
        */
        const db = getDatabaseHandle()

        api.get('/commodities')
        .then(function (response){
            console.log('Commodities THEN ', response.data);
            if(response.status == 200){
                setCommodityData(saveCommodities(db, response.data.data));
            }
        })
        .catch(function (error) {
            console.log('Error loading commodities ', error);
        });

        api.get('/companies')
        .then(function (response){
            console.log('Companies THEN ', response.data);            
            if(response.status == 200){
                setCompanyData(saveCompanies(db, response.data.data));
            }
        })
        .catch(function (error) {
            console.log('Error loading companies ', error);
        });
        
    };

    // Probably should delete all the existing dm_commodities prior to this bulkPut OR
    // does .stores kill the db beforehand ???
    const saveCommodities = (db, comms) => {
        db.dm_commodities.clear();
        const newcomms = comms.map(c => ({commodity:c.attributes.commodity, commodity_desc:c.attributes.commodity_desc, commodity_id : c.id }));
        newcomms.forEach(element => {
            console.log(element);            
        });        
        db.dm_commodities.bulkPut(newcomms).then(() => console.log('Saved new commodities')).catch((error) => console.error('Error saving commodities ', error));
        return newcomms;
    }
    // Probably should delete all the existing dm_commodities prior to this bulkPut
    const saveCompanies = (db, comps) => {
        db.dm_companies.clear();
        const newcomps = comps.filter(c => c.attributes.active).map(c => ({company_name:c.attributes.company_name, cusno:c.attributes.cusno, company_id : c.id , company_image : c.attributes.image_token}));
        
        newcomps.forEach(element => {
            console.log(element);            
        });
        db.dm_companies.bulkPut(newcomps).then(() => console.log('Saved new compnaies')).catch((error) => console.error('Error saving compnaies ', error));
        return newcomps;
    }

    const getDatabaseHandle = () => {
        const db = new Dexie('harvyst');
        try {
            db.version(2).stores({
                dm_commodities: '&commodity_id, commodity, commodity_desc',
                dm_companies: '&company_id, company_name, cusno, company_image',
                dm_usersettings: '&key, value',
                dm_ini: '++id, key, value'  
            });

        } catch (error) {
            console.error(error);            
        }
        return db;        

    }

    const LoadUserSettings = () => {
        console.log('Loading UserSettings()');
        try {
            const db = getDatabaseHandle();

            db.dm_usersettings.toArray()
            .then(dm_usersettings => {
                const us = settingsJSONtoObj(dm_usersettings);
                setUserSettings(us);
                console.log('US Loaded:', us)
             })
            .catch(e => console.error('Error loading user settings', e));

  
        } catch (error) {
            console.error(error);            
        }
    };

    const LoadDataModel = () => {
        console.log('Loading DataMode()');
        try {
            const db = getDatabaseHandle();

            db.dm_commodities.orderBy('commodity').toArray()
            .then(dm_commodities => {
                 setCommodityData(dm_commodities);
             })
            .catch(e => console.error('Error loading commodities', e));

            db.dm_companies.orderBy('company_name').toArray()
            .then(dm_companies => {
                  setCompanyData(dm_companies);
              })
            .catch(e => console.error('Error loading companies', e));
   
        } catch (error) {
            console.error(error);            
        }
    };

    useEffect(()=>{
       // CreateStores();
        LoadDataModel();
        LoadUserSettings();
        //reloadDataModel();
        console.log('DataContext useEffect()');
    }, []);

    return (
        <DataContext.Provider value={{ commodity_data, company_data, dataModel, userSettings, reloadDataModel, reloadUserSettings }}>
            {children}
        </DataContext.Provider>
    );
};

const useDataContext = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

export { DataProvider, useDataContext };
