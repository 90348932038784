import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const generateFakeData = (numDataPoints, timeInterval) => {
    const fakeData = [];
    const currentTime = new Date().getTime();

    for (let i = 0; i < numDataPoints; i++) {
        const timestamp = currentTime - i * timeInterval;
        fakeData.push({
            timestamp,
            value: Math.random() * 100,
        });
    }

    return fakeData.reverse();
};


const Chart2 = () => {
    //const { symbol, timeIntervalValue } = chartOptions;
    const symbol = 'XYZP';
    const timeIntervalValue = 20;

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
      const interval = setInterval(() => {
        const newVals = [{x: new Date(), y : Math.floor(400 + Math.random()*50)}];
        let newData = [...chartData, ... newVals];
        newData = newData.slice(-150);
        setChartData(newData);
      }, 5000);

      return () => {
        window.clearInterval(interval);
      };
    }, [chartData]);



    const series = [{
        name: 'Corn Futures Price',
        data: chartData
        /*[
            //350, 355, 360, 370, 365, 375, 380] // Dummy data for prices
            { x: new Date('2023-01-01').getTime(), y: 350 },
            { x: new Date('2023-02-01').getTime(), y: 355 },
            { x: new Date('2023-03-01').getTime(), y: 360 },
            { x: new Date('2023-04-01').getTime(), y: 370 },
            { x: new Date('2023-05-01').getTime(), y: 365 },
            { x: new Date('2023-06-01').getTime(), y: 375 },
            { x: new Date('2023-07-01').getTime(), y: 380 }
        ]*/
      }];
    const options = {
        chart: {
          type: 'line',
          stacked: false,
          height: 350,
          
          zoom: {
            enabled: true,
            autoScaleYaxis: true,
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          curve: 'straight'
        },
        title: {
          text: 'Corn Futures Prices',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          type: 'datetime', //categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], // Dummy months
        }
      };
   

    useEffect(() => {

    }, [symbol, timeIntervalValue]);

    return <ReactApexChart options={options} series={series} type='line' height={350}></ReactApexChart> ;
    //return <><ReactApexChart {...chartData} type='area'/><button onClick={handleRemove}>X</button></> 
    //return chartData && <ReactApexChart options={chartData.options} series={chartData.options} type="area" height={350} />
              
};

export default Chart2;

