import React, { useState, useEffect } from 'react';
import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import api from '../../services/ApiConfig';


const ContactModal = ({ show, hide, editMode, company, contact }) => {
    const [newContact, setNewContact] = useState({
        title: '',
        contact_name: '',
        email: '',
        phone: '',
        cell: '',
        notes: '',
        primary_contact: 'F',
    });

    const [errors, setErrors] = useState({
        title: '',
        contact_name: '',
        email: '',
        phone: '',
        cell: '',
        notes: '',
    });

    const CONTACTS_URL = '/contacts';

    useEffect(() => {

        console.log(company)


        if (editMode) {
            // Set initial contact values if in edit mode
            setNewContact({
                title: contact.attributes.title || '',
                contact_name: contact.attributes.contact_name || '',
                email: contact.attributes.email || '',
                phone: contact.attributes.phone || '',
                cell: contact.attributes.cell || '',
                notes: contact.attributes.notes || '',
                primary_contact: contact.attributes.primary_contact ? 'T' : 'F',
            });
        } else {
            // Reset form if in add mode
            setNewContact({
                title: '',
                contact_name: '',
                email: '',
                phone: '',
                cell: '',
                notes: '',
                primary_contact: 'F',
            });
        }
    }, []);

    const handleContactChange = (event) => {
        setNewContact({ ...newContact, [event.target.contact_name]: event.target.value });
    };

    const validateForm = () => {
        const newErrors = {
            title: '',
            contact_name: '',
            email: '',
            phone: '',
            cell: '',
            notes: '',
        };

        // Validation logic similar to address validation

        setErrors(newErrors);

        // Return true if there are no errors, false otherwise
        return Object.values(newErrors).every((error) => error === '');
    };

    const handlePostContact = async () => {
        try {
            const isValid = validateForm();

            if (company && isValid) {
                const contactWithCompanyId = {
                    ...newContact,
                    company_id: company.attributes.company_id,
                };

                const res = await api.post(CONTACTS_URL, contactWithCompanyId);
                hide();

            } else {
                console.error('Validation failed or no company selected. Please check the required fields.');
            }
        } catch (error) {
            console.error('Error adding contact:', error);
        }
    };

    // Similar error handling can be added to handlePutContact


    const handlePutContact = async () => {

        try {
            const isValid = validateForm();

            if (isValid) {
                // Clone the newContact object to avoid modifying the original object
                const updatedContact = { ...newContact };

                // Add a check for primary_contact and convert it to 'F' or 'T'

                const res = await api.put(`${CONTACTS_URL}/${contact.attributes.contact_id}`, updatedContact);
                hide();
            } else {
                console.error('Validation failed. Please check the required fields.');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleHide = () => {
        setErrors('');
        hide();
    }

    return (
        <Modal show={show} onHide={handleHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{editMode ? 'Edit Contact' : 'Add Contact'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5 className="m-3">{editMode ? 'Edit Contact' : 'Add New Contact'}</h5>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newContact.title}
                                            onChange={(e) => setNewContact({ ...newContact, title: e.target.value })}
                                            placeholder='Enter contact title'

                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newContact.contact_name}
                                            onChange={(e) => setNewContact({ ...newContact, contact_name: e.target.value })}
                                            placeholder='Enter contact name'
                                            isInvalid={!!errors.contact_name}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.contact_name}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newContact.email}
                                            onChange={(e) => setNewContact({ ...newContact, email: e.target.value })}
                                            placeholder='Enter contact email'
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newContact.phone}
                                            onChange={(e) => setNewContact({ ...newContact, phone: e.target.value })}
                                            placeholder='Enter contact phone'
                                            isInvalid={!!errors.phone}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Cell</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={newContact.cell}
                                            onChange={(e) => setNewContact({ ...newContact, cell: e.target.value })}
                                            placeholder='Enter contact cell'
                                        />
                                    </Form.Group>
                                </Col>

                                <Col xs={3}>
                                    <Form.Group>
                                        <Form.Label>Primary Contact</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            checked={newContact.primary_contact === "T"}
                                            onChange={(e) => setNewContact({ ...newContact, primary_contact: e.target.checked ? "T" : "F" })}
                                        />
                                    </Form.Group>
                                </Col>


                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Notes</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            value={newContact.notes}
                                            onChange={(e) => setNewContact({ ...newContact, notes: e.target.value })}
                                            placeholder='Enter contact notes'
                                            style={{ maxHeight: '100px', resize: 'vertical' }}
                                            rows={3}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                    Close
                </Button>
                <Button variant="primary" onClick={editMode ? handlePutContact : handlePostContact}>
                    {editMode ? 'Save Contact' : 'Add Contact'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactModal;
