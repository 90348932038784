import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Chart from './components/Chart';
//import NewChartModal from './components/NewChartModal';
import Chart2 from './components/Chart2';
import '../../styles/marketdata.css';




function MarketData() {
    const [charts, setCharts] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleRemoveChart = (symbolToRemove) => {
        setCharts((prevCharts) => prevCharts.filter((chart) => chart.symbol !== symbolToRemove));
    };
    

    // Inside MarketData component
    return (
        <div id='marketdata-div'>
            <div className="mb-2">
                <h2>Market Data</h2>
                <Button className="harvyst-green" onClick={handleOpenModal}>
                    <FontAwesomeIcon icon={faPlus} /> Add chart
                </Button>
            </div>
            <Row className="justify-content-md-center">
                {charts.map((chart) => (
                    <Col key={chart.symbol} xxl={4} xl={4} lg={6} md={12} sm={12} xs={12}>
                        <Chart chartOptions={chart} removeChart={() => handleRemoveChart(chart.symbol)} />
                    </Col>
                ))}
            </Row>



            <Chart2></Chart2>
        </div>
    );

}

export default MarketData;
