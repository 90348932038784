// TicketCard.js
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faEllipsisV, faPenToSquare, faFolderOpen, faTrash, faShare, faFile} from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../../../services/AuthContext';
import api from '../../../services/ApiConfig';



const TicketEntry = ({ ticket, removeTicketCB, editTicketCB, asCard = false }) => {
//    const navigate = useNavigate();

    const { token } = useAuthContext();

   
    const handleTicketDelete = async (tn) =>{
        const res = await api.delete('/tickets/'+tn);
        removeTicketCB(tn);
    };

    const handleTicketEdit = async (tn) =>{
        editTicketCB(tn);
    };

    const actionPDF = (tn) => {

        var options = {
            method: 'GET',
            headers:{
                'Authorization': 'Bearer ' + token,
            }
        };

        // Change this to use your HTTP client
        fetch('http://'+window.location.hostname+'/api/v1/reports/ticket/'+ tn, options ) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
              console.log(err);
            });
    };







    if (asCard) {
        return (
            <Card>
                <Card.Body className="d-flex flex-column">
                    <Card.Title className="d-flex justify-content-between">
                        {ticket.scaleId || ticket.ticketNumber}
                        {/* Dropdown for actions */}
                        <Dropdown>
                            <Dropdown.Toggle variant="blank" id="dropdown-basic">
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <Dropdown.Item className=''>
                                    <FontAwesomeIcon icon={faShare} /> Share
                                </Dropdown.Item>
                                <Dropdown.Item className=''>
                                <div onClick={() => actionPDF(ticket.ticketNumber)}><FontAwesomeIcon icon={faFile} /> PDF</div>
                                </Dropdown.Item>
                                <Dropdown.Item className=''>
                                <div onClick={() => handleTicketEdit(ticket.ticketNumber)}><FontAwesomeIcon icon={faPenToSquare} /> Edit</div>
                                </Dropdown.Item>
                                <Dropdown.Item className='text-danger' onClick={() => handleTicketDelete(ticket.ticketNumber)}>
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <FontAwesomeIcon icon={faFolderOpen} />
                                    {ticket.isArchived ? ' Restore' : ' Archive'}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Card.Title>

                    <Card.Subtitle className="mb-2 text-muted">Transaction Type: {ticket.transactionType}</Card.Subtitle>
                    <Card.Text>
                        <strong>Net Weight:</strong> {ticket.netWeight}<br />
                        <strong>Scale ID:</strong> {ticket.scaleId}<br />
                        <strong>Company Name:</strong> {ticket.companyName}<br />
                        <strong>Commodity:</strong> {ticket.commodity}<br />
                        <strong>Date:</strong> {ticket.date}<br />
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }

    // Render as table row
    return (
        <tr>
            <td>{ticket.scaleId || ticket.ticketNumber}</td>
            <td>{ticket.companyName}</td>
            <td>{ticket.commodity}</td>
            <td>{ticket.transactionType}</td>
            <td>{ticket.netWeight}</td>
            <td>{ticket.date}</td>
            <td>
                <div className="d-flex align-items-center">
                    <Dropdown>
                        <Dropdown.Toggle variant="blank" id="dropdown-basic">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item className=''>
                                <FontAwesomeIcon icon={faShare} /> Share
                            </Dropdown.Item>
                            <Dropdown.Item className=''>
                                <div onClick={() => actionPDF(ticket.ticketNumber)}><FontAwesomeIcon icon={faFile}/> PDF</div>
                            </Dropdown.Item>
                            <Dropdown.Item className=''>
                                <div onClick={() => handleTicketEdit(ticket.ticketNumber)}><FontAwesomeIcon icon={faPenToSquare} /> Edit</div>
                            </Dropdown.Item>
                            <Dropdown.Item className='text-danger' onClick={() => handleTicketDelete(ticket.ticketNumber)}>
                                <FontAwesomeIcon icon={faTrash} /> Delete
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <FontAwesomeIcon icon={faFolderOpen} />
                                {ticket.isArchived ? ' Restore' : ' Archive'}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </td>
        </tr>
    );
};

export default TicketEntry;
