// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Title style */
.card-title {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Hover effect */
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/styles/cashbids.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA,iBAAiB;AACjB;EACE,2BAA2B;EAC3B,yCAAyC;EACzC,gCAAgC;AAClC","sourcesContent":["/* Title style */\n.card-title {\n  font-size: 24px;\n  margin-bottom: 10px;\n}\n\n/* Hover effect */\n.card:hover {\n  transform: translateY(-5px);\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\n  transition: all 0.2s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
