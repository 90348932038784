import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const QuickMarketStats = () => {
    const marketData = [
        {
            Change: 200,
            high: 400,
            last: 100,
            low: 500,
            modified_ts: 'Wed, 09 Aug 2023 19:41:15 GMT',
            open: 300,
            symbol: 'TEST1',
        },
        {
            Change: 150,
            high: 380,
            last: 120,
            low: 480,
            modified_ts: 'Tue, 08 Aug 2023 18:35:22 GMT',
            open: 320,
            symbol: 'TEST1',
        },
        {
            Change: 180,
            high: 420,
            last: 110,
            low: 490,
            modified_ts: 'Mon, 07 Aug 2023 17:29:10 GMT',
            open: 310,
            symbol: 'TEST1',
        },
        {
            Change: 220,
            high: 430,
            last: 130,
            low: 470,
            modified_ts: 'Sun, 06 Aug 2023 16:20:48 GMT',
            open: 330,
            symbol: 'TEST1',
        },
        {
            Change: 190,
            high: 410,
            last: 105,
            low: 485,
            modified_ts: 'Sat, 05 Aug 2023 15:15:30 GMT',
            open: 290,
            symbol: 'TEST1',
        },
    ];

    const chartData = marketData.map(dataPoint => ({
        timestamp: new Date(dataPoint.modified_ts).toLocaleDateString(),
        price: dataPoint.last,
    }));

    return (
        <div className="">
            <h3>Market Data for {marketData[1].symbol}</h3>
            <div className="row">
                <div className="col-md-12">
                    <div className="panel quick-market-stats">
                        <div className="chart-container">
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="timestamp" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="price" stroke="var(--harvyst-green)" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {/* Other components */}
                </div>
            </div>
        </div>
    );
};

export default QuickMarketStats;
