import React, { useEffect, useState } from 'react';
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import CashbidCard from './CashbidCard';
import CreateCashbidModal from './CreateCashbidModal';
import getCommodityIcon from './CommodityIcon';

const baseURL = 'http://192.168.1.41:5000/cashbids';

function Cashbids({ darkMode }) {
    const [cashbids, setCashbids] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showArchived, setShowArchived] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleCreateCashbid = (cashbidData) => {
        console.log('Creating cash bid:', cashbidData);


        setShowModal(false);
    };


    useEffect(() => {
        // Fetch data from the API
        axios.get(baseURL)
            .then(response => setCashbids(response.data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div>
            <div className='mb-2'>
                <h1>Cash Bids</h1>
                <h6 className={darkMode ? ' ' : 'text-muted'}>
                    Purchase or bid for commodities. Click on a cash bid to view more details.
                </h6>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center gap-2">
                            <Button
                                className="harvyst-green"
                                onClick={toggleModal}
                            >
                                <FontAwesomeIcon icon={faPlus} /> Create Cash Bid
                            </Button>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 1000, hide: 400 }}
                                overlay={<Tooltip className='button-tooltip'>Archive will hide away items without deleting them</Tooltip>}
                            >
                                <Form.Check
                                    type="switch"
                                    label="Show Archived"
                                    id="archive-switch"
                                    checked={showArchived}
                                    onChange={() => setShowArchived(!showArchived)}
                                />
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
            {cashbids.length === 0 ? (
                <div className='text-center'>
                    <h4>No cash bids!</h4>
                </div>
            ) : (
                <Row hover>
                    {cashbids.map((cashbid, index) => {
                        if (cashbid.isArchived && !showArchived) {
                            return null;
                        }
                        return (
                            <Col key={index} lg={3} md={4} sm={12}>
                                <Link to={`/cashbid/${cashbid.id}`} style={{ textDecoration: 'none' }}>
                                    <CashbidCard cashbid={cashbid} />
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
            )}
            <CreateCashbidModal show={showModal} onHide={toggleModal} onCreateCashbid={handleCreateCashbid} />
        </div >
    );
};

export default Cashbids;
