import React from 'react';

const LoadingSpinner = () => (
    <div className="text-center fade-animation">
        Loading...
        <div className="spinner-border spinner-border-sm " role="status">
            <span className="sr-only"></span>
        </div>
    </div>
);

export default LoadingSpinner;

<style>
    {`
    @keyframes fade {
      0%, 100% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }

    .fade-animation {
      animation: fade 0.5s ease-in-out infinite;
    }
  `}
</style>
