import React from 'react';
import Alert from 'react-bootstrap/Alert';
import '../styles/errorbox.css';


const NoticeBox = ({ noticeMsg }) => {
    return (
      <>
      <Alert variant='info'>
          {noticeMsg}
        </Alert>
        </>            
    );
}

export default NoticeBox;