// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom.css */
/* Use the class of the object to over ride any css from bootstrap */
`, "",{"version":3,"sources":["webpack://./src/styles/custom.css"],"names":[],"mappings":"AAAA,eAAe;AACf,oEAAoE","sourcesContent":["/* custom.css */\n/* Use the class of the object to over ride any css from bootstrap */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
