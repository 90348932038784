import React from 'react';
import Alert from 'react-bootstrap/Alert';
import '../styles/errorbox.css';


const ErrorBox = ({ errorMsg }) => {
    return (
      <>
      <Alert variant='danger'>
          {errorMsg}
        </Alert>
        </>            
    );
}

export default ErrorBox;