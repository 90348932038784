
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faCheckCircle, faXmarkCircle, faTrash, faPenToSquare, faFolderOpen, faEye } from '@fortawesome/free-solid-svg-icons';
import '../../styles/news.css';

const InventoryEntry = ({ inv }) => {
    const { commodity_name, elevator, stored, total } = inv;

    return (
        <tr className={`inventory-entry`}>
            < td > {commodity_name}</td >
            <td>{elevator}</td>
            <td>{stored}</td>
            <td>{total}</td>

        </tr >
    );
};

export default InventoryEntry;