import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSeedling,
    faWheatAwn,
    faCarrot,
    faLeaf,
    faPlantWilt,
} from '@fortawesome/free-solid-svg-icons';

// Map commodity names to corresponding Font Awesome icons
const getCommodityIcon = (commodity) => {
    switch (commodity.toLowerCase()) {
        case 'corn':
            return <FontAwesomeIcon icon={faSeedling} color='GoldenRod' size='lg' />;
        case 'wheat':
            return <FontAwesomeIcon icon={faWheatAwn} color='Gold' size='lg' />;
        case 'bean':
            return <FontAwesomeIcon icon={faCarrot} color='orange' size='lg' />;
        case 'soybean':
            return <FontAwesomeIcon icon={faPlantWilt} color='MediumSeaGreen' size='lg' />;
        default:
            return <FontAwesomeIcon icon={faLeaf} />;
    }
};

export default getCommodityIcon;
