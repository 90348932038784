import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthContext } from '../services/AuthContext'

import { Launcher } from 'popup-chat-react'; // https://github.com/asliddinusmonov/popup-chat-react/blob/master/README.md           
import Navbar from './Navbar';
import SideNav from './SideNav';
import Footer from './Footer';

import '../styles/auntganny.css'


//const ProtectedRoute = ({element : Element}) => {
const ProtectedRoute = ({children}) => {
    

    const [auntGanny, setAuntGanny] = useState({messageList: [], newMessagesCount: 0, isOpen: false, fileUpload: false,});
    
    const [darkMode, setDarkMode] = useState(false);
    const darkModeToggle = async () => {
        const newDarkMode = !darkMode;
        localStorage.setItem('darkmode', newDarkMode);
        setDarkMode(newDarkMode);
        /*
        try {
            await api.put('/usersettings/darkmode', { value: newDarkMode.toString() });
            localStorage.setItem('darkmode', newDarkMode);

        } catch (error) {
            console.error('Network error:', error);
        } finally {
            setDarkMode(newDarkMode);
        }
        */
    };

    
    const { userLoggedIn } = useAuthContext();
    if(!userLoggedIn()){
        return <Navigate to ='/login'/>;
    }
      
    function onMessageWasSent(message) {
        setAuntGanny(auntGanny => ({...auntGanny, messageList: [...auntGanny.messageList, message] }));
        recvMessage(message.data.text);
    }
    
    function onFilesSelected(fileList) {
        console.error('onFilesSelected');
    }
    
    // Receive new data
    function recvMessage(text) {
  
        text = text.split("").reverse().join("");
        text = 'As of 10:12am the value of Mar23 CORN is 447-2 or USD $4.4725 / Bu.'
        let m = { author: 'Aunt Ganny', type: 'text', data: {text: text}};

        if (text.length > 0) {
            const newMessagesCount = auntGanny.isOpen ? auntGanny.newMessagesCount : auntGanny.newMessagesCount + 1;
            //console.log('SEND MESSAGE');
            setAuntGanny(auntGanny => ({ ...auntGanny, newMessagesCount: newMessagesCount, messageList: [...auntGanny.messageList, m]
            }));
        }
    }
    
    function onAuntGannyClick() {
        setAuntGanny(auntGanny => ({
          ...auntGanny, isOpen: !auntGanny.isOpen, newMessagesCount: 0
        }));
    } 
  /*   
    return (
        <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>

        <div className='app-content'>
            {children}
        </div>             
        </div>
    );
    
*/

    return (
        <div className='protected-div'>
        <Navbar />
        <SideNav children={children} />        
        <Launcher 
            agentProfile={{
                teamName: 'Aunt Ganny',
                imageUrl: '/images/AuntGanny2.png'
            }}
            // docs say this isnt required.  However, it generates an error if not present
            // "Failed prop type: The prop `onFilesSelected` is marked as required in `UserInput`, but its value is `undefined`."
            onFilesSelected={onFilesSelected} 
            onMessageWasSent={onMessageWasSent}
            messageList={auntGanny.messageList}
            newMessagesCount={auntGanny.newMessagesCount}
            onClick={onAuntGannyClick}
            isOpen={auntGanny.isOpen}
            showEmoji
            pinMessage={{
                imageUrl: 'http://localhost:3000/images/AuntGanny2.png',
                title: 'Welcome to HARVYST',
                text: 'How may I help you?'
            }}
        />                        
        {/*<Footer/> */}
        </div>
    );
    

}; 

export default ProtectedRoute