import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Spinner } from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import { TextInputWithSideLabel, NumberInput, SelectWithSideLabel, InputAreaWithSideLabel, InputWithSideLabelAndFactor, TextWithLabel, SelectWithLabel, InputWithSideLabelAndButton } from './components/input';
import StickyBar from './components/stickyBar';
import api from '../../services/ApiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faWeightHanging, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import "../../styles/tickets.css";
import "./components/weightOverwriteModal";
import { useDataContext } from '../../services/DataContext';
import ErrorBox from '../../components/ErrorBox';
import NoticeBox from '../../components/NoticeBox';

function NewTicket() {
    const tid = useParams(); // tid is object {id:'018S17AX'}
    const { commodity_data, company_data } = useDataContext();

    const {ticketId, setTicketId} = useState(tid.id);
    const [tmpTicketObj, setTmpTicketObj] = useState({
        ticket_id : tid.id,
        //ticket_dt: new Date().toISOString().split('T')[0], 
        transaction_type : 'hold',
        net_weight : 0,
        /*
        scaleticket: '',
        //company_id : ,
        //commodity_id :'',
        crop_year :'',
        moisture_pct : 0.0000,
        dockage_pct : 0.0000,
        dockage : 0,
        grade :'',
        factor : 0,
        shrink : 0,
        comments : '',
        net_weight : 0.0000,
        gross_weight : 0.0000,
        tare_weight : 0.0000,
        field : '',
        ref : '',
        disc_amt1 : 0.00,
        disc_amt2 : 0.00,
        disc_amt3 : 0.00,                <p key={index} style={{ color: 'yellow', margin: 0 }}>
                                {warning}
                            </p>
        is_complete : false,
        */
    });

    const [ticketObj, setTicketObj] = useState(tmpTicketObj);



    const [isFetching, setIsFetching] = useState(false);
    // TODO:  Put CompanyName is DataContext();
    const [companyName, setCompanyName] = useState('');
    const [errors, setErrors] = useState([]);
    const [warnings, setWarnings] = useState([]);

    const commodity_data_delta = commodity_data.map( c => 
        ({id:c.commodity_id, label:c.commodity, value:c.commodity_id})
    );
    const company_data_delta = company_data.map( c => 
        ({id:c.company_id, label:c.company_name, value:c.company_id})
    );

    const navigate = useNavigate();
        

const setTmpTicketData = (fieldName, fieldValue, fieldType) =>{
    let convertedValue;

    // Convert based on data type
    if (fieldValue === null) {
        convertedValue = '';    // This is to catch any values that are blank, sending it over will cause a type error
    }
    else if (fieldType === 'integer') {
        convertedValue = parseInt(fieldValue, 10);
    } else if (fieldType === 'float') {
        const floatValue = parseFloat(fieldValue);
        convertedValue = parseFloat(floatValue.toFixed(4));
    } else if (fieldType === 'boolean') {
        convertedValue = fieldValue === 'true' ? true : false;
    }
    else if (fieldType === 'string') {
        convertedValue = fieldValue.toString();
    }    
    setTmpTicketObj({...tmpTicketObj, [fieldName]:convertedValue});
}

const handleOnBlur = (fieldName) =>{
    // Compare
    if(ticketObj[fieldName] !== tmpTicketObj[fieldName]){
        //setTicketObj(tmpTicketObj);
        setTicketObj({...ticketObj, [fieldName]:tmpTicketObj[fieldName]});
        // Only in some cases call the API.
        // For now using all cases.
        validateTicket(false);
    }
}

/*
useEffect(() => {
    console.log('useEffect() for ticketObj')
    validateTicket(false);
}, [ticketObj]);
*/


const validateTicket = (isC = false) => {

        setIsFetching(true);
        try {
            console.log('validateTicket()');

            //
            // The question is do we send the WHOLE ticket
            // Or just the piece that has changed.
            // a) Sending the whole ticket .... I can then avoid sending anything 
            //    that doesnt effect the ticket (eg. comments) until we are saving OR 
            //    we send something that does effect (eg gross_weight).
            // b) Sending just the piece that changed.  We will have to send 
            //    everything everytime.  Otherwise the server (which sends back the whole ticket)
            //    doesnt know about the comments change.
            // Trying a)

            // OKAY HERE IS THE POOP.  If I send all the data and simply ingest all the data 
            // into ticketObj ... then we have and issue as the API sends back {harvyst_id = X}
            // But the PUT requests automatically will fail if you add harvyst_id
            // delete updatedTicketData.harvyst_id;
            // Im going back to generating the temporary object.
            const tO = packageTicket();

            if(isC){
                tO.is_complete = true;
            }else{
                tO.is_complete = false;
            }

            const res = api.put(`/tickets/${ticketObj.ticket_id}`, tO)
            .then((res) =>{
                console.log('validateTicket RESULTS ...', res);

                // Extract relevant data from the API response
                const updatedTicketData = res.data.data[0].attributes;
    
                if (res.data.errors && res.data.errors.length > 0) {
                    console.log('ERRORS:', res.data.errors)
                    setErrors(res.data.errors);
                } else {
                    setErrors([]);
                }
    
                if (res.data.notices && res.data.notices.length > 0) {
                    setWarnings(res.data.notices);
                } else {
                    setWarnings([]);                 
                }

               setTmpTicketObj(updatedTicketData);  
               setTicketObj(updatedTicketData);                                                           ;
            }).catch((e)=> console.error('E:', e));

            // Handle errors and warnings
            //console.log(res.data)
        } catch (err) {
            // If the API throws a 500 then the awaited promise throws and exception.
            console.error('Thrown Exceptions: ', err);
        }finally{
            setIsFetching(false);
        }
    };


const packageTicket = () =>{
    let tO = {};
    
//    tO.ticket_id = ticket_id;
    tO.scaleticket = tmpTicketObj.scaleticket;
    tO.transaction_type = tmpTicketObj.transaction_type;
    if(tmpTicketObj.company_id != null){tO.company_id = tmpTicketObj.company_id;}
    if(tmpTicketObj.commodity_id != null){tO.commodity_id = tmpTicketObj.commodity_id;}

    tO.crop_year = tmpTicketObj.crop_year;
    tO.moisture_pct = tmpTicketObj.moisture_pct;
    tO.dockage_pct = tmpTicketObj.dockage_pct;
//    tO.dockage = dockage;
    tO.grade = tmpTicketObj.grade;
//    tO.factor = factor;
//    tO.shrink = shrink;
    tO.comments = tmpTicketObj.comments;
//    tO.net_weight = ticketObj.net_weight;
    tO.gross_weight = tmpTicketObj.gross_weight;
    tO.tare_weight = tmpTicketObj.tare_weight;
    tO.field = tmpTicketObj.field;
    if (tmpTicketObj == null) {tO.reference_number = ''} else { tO.reference_number = tmpTicketObj.reference_number; }
    tO.disc_amt1 = tmpTicketObj.disc_amt1;
    tO.disc_amt2 = tmpTicketObj.disc_amt2;
    tO.disc_amt3 = tmpTicketObj.disc_amt3;
    tO.is_complete = tmpTicketObj.isComplete;
    //console.log('trrrr', tO);
    
    return tO;
}
    
    const handleSaveTicket =  () => {
        console.log('Saving');

        validateTicket(true)
        if(tmpTicketObj.transaction_type == 'hold'){
            navigate('/tickets');
        }
        if((errors.length <= 0) && (tmpTicketObj.is_complete)){
            navigate('/tickets');
        }

        /*
        validateTicket(true)
        .then(function (res){
            console.log('In then ...', tmpTicketObj.transaction_type);
            if(tmpTicketObj.transaction_type == 'hold'){
                navigate('/tickets');
            }
            console.log('Pre Error check', tmpTicketObj.is_complete);
            if((errors.length <= 0) && (tmpTicketObj.is_complete)){
                navigate('/tickets');
            }
        })
        .catch(function (error) {
            //addErrorToast('Error saving ticket');
            console.log('Error retrieving ticket details ', error);
        });       
        */
        
    };

    const handleCancelTicket = () => {
        // Implement logic to cancel the ticket
    };



    useEffect(() => {
        validateTicket(false);
    }, [ticketId]);
    

    return (
        <div>
            <h1>Create New Ticket {isFetching && <Spinner animation="grow" variant="secondary" size="m" className="ml-2" />}</h1>


            <Row className='border p-3 my-3 harvyst-green-dark shadow-sm rounded-3'>
                <div className="col-lg-2 ">
                    <div className="text-white">Ticket ID: {ticketObj.ticket_id}</div>
                    <Button className="ml-2"  variant="primary" onClick={handleSaveTicket} disabled={isFetching}>Save</Button>
                </div>

                <div className="col-lg-2 ">

                    <TextWithLabel
                        label="Scale Ticket"
                        value={tmpTicketObj.scaleticket}
                        onChange={(e) => setTmpTicketData('scaleticket', e.target.value, 'string')}
                        onBlur={(e) => handleOnBlur('scaleticket')}
                        placeholder="Enter Scale Ticket"
                        autoFocus={true}    //Sets the focus to this field when the page loads

                    />
                </div>
                <div className="col-lg-2 ">
                    <Form.Group controlId="ticketDate" className='mt-2'>
                        <Form.Label>Ticket Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={tmpTicketObj.ticket_dt}
                            onChange={(e) => setTmpTicketData('ticket_dt', e.target.value, 'string')}
                            onBlur={(e) => handleOnBlur('ticket_dt')}
                        />
                    </Form.Group>
                </div>
                <div className="col-lg-2 ">
                    <SelectWithLabel
                        label="Company Name"
                        value={tmpTicketObj.company_id}
                        onChange={(e) => setTmpTicketData('company_id', e.target.value, 'integer')}
                        onBlur={handleOnBlur('company_id')}
                    options={company_data_delta}
                        placeholder="Select Company"
                    />
                </div>
                <div className="col-lg-2 ">
                    <SelectWithLabel
                        label="Commodity"
                        value={tmpTicketObj.commodity_id}
                        onChange={(e) => setTmpTicketData('commodity_id', e.target.value, 'integer')}
                        onBlur={(e) => handleOnBlur('commodity_id')}
                        options={commodity_data_delta}
                        placeholder="Select Commodity"
                    />

                </div>
                <div className="col-lg-1">
                    <SelectWithLabel
                        label="Transaction"
                        value={tmpTicketObj.transaction_type}
                        onChange={(e) => setTmpTicketData('transaction_type', e.target.value, 'string')}
                        onBlur={(e) => handleOnBlur('transaction_type')}
                        options={[
                            { value: 'bought', label: 'Bought' },
                            { value: 'sold', label: 'Sold' },
                            { value: 'stored', label: 'Stored' },
                            { value: 'hold', label: 'Hold' }
                        ]}
                    />
                </div>
                <div className="col-lg-1 ">
                    <TextWithLabel
                        label="Field"
                        value={tmpTicketObj.field}
                        onChange={(e) => setTmpTicketData('field', e.target.value, 'string')}
                        onBlur={(e) => handleOnBlur('field')}
                        placeholder="Enter Field"
                    />
                </div>

            </Row>

            {!isFetching && (
                    <>
                        {errors.slice(-3).map((error, index) => (
                            <ErrorBox errorMsg={error}></ErrorBox>
                        ))}

                        {warnings.slice(-3).map((warning, index) => (
                            <NoticeBox noticeMsg={warning}></NoticeBox>                            
        
                        ))}
                    </>
                )}            

            <Row className="row-cols-1 row-cols-md-1 row-cols-lg-4">
                <div className='p-1'>
                    <div className="h-100 bg-light border border-dark border-1 p-3 rounded-2">
                        <h4 className='text-center'>Weights</h4>

                        <InputWithSideLabelAndButton
                            label="Incoming"
                            type='number'
                            value={tmpTicketObj.gross_weight}
// Not working because 'type' (above) is number .. doesnt work if type = text                            value={gross_weight.toLocaleString('en')  }
                            onChange={(e) => setTmpTicketData('gross_weight', e.target.value, 'integer')}
                            onBlur={(e) => handleOnBlur('gross_weight')}
                            placeholder="Enter Gross Weight"
                            buttonIcon={faWeightHanging}  // Pass the Font Awesome icon
                            onClick={() => console.log('Scale')}   // Add button logic
                        />

                        <InputWithSideLabelAndButton
                            label="Outgoing"
                            type='number'
                            value={tmpTicketObj.tare_weight}
                            onChange={(e) => setTmpTicketData('tare_weight', e.target.value, 'integer')}
                            onBlur={(e) => handleOnBlur('tare_weight')}
                            placeholder="Enter Tare Weight"
                            button=""
                            onClick={() => console.log('Scale')}   //Add button logic
                            buttonIcon={faWeightHanging}
                        />

                        <TextInputWithSideLabel
                            label="Factor"
                            value={tmpTicketObj.factor}
                            placeholder="Factor (Read Only)"
                            isDisabled={true}
                        />
                        <TextInputWithSideLabel
                            label="Shrink"
                            value={tmpTicketObj.shrink}
                            placeholder="Shrink (Read Only)"
                            isDisabled={true}
                        />
                        <TextInputWithSideLabel
                            label="Dockage"
                            value={tmpTicketObj.dockage}
                            placeholder="Dockage"
                            isDisabled={true}
                        />

                        <TextInputWithSideLabel
                            label="Net"
                            value={tmpTicketObj.net_weight}
                            placeholder="Net Weight (Read Only)"
                            isDisabled={true}
                        />
                    </div>
                </div>

                <div className='p-1'>
                    <div className="h-100  bg-light border border-dark border-1 p-3 rounded-2">
                        <h4 className='text-center'>Discounts</h4>

                        <NumberInput
                            label="Dockage Percentage"
                            value={tmpTicketObj.dockage_pct}
                            onChange={(e) => setTmpTicketData('dockage_pct', e.target.value, 'float')}
                            onBlur={(e) => handleOnBlur('dockage_pct')}
                            placeholder="Enter Dockage Percentage"
                            min={0.00}
                            max={2.00}
                        />

                        <NumberInput
                            label="Moisture Percentage"
                            value={tmpTicketObj.moisture_pct}
                            onChange={(e) => setTmpTicketData('moisture_pct', e.target.value, 'float')}
                            onBlur={(e) => handleOnBlur('moisture_pct')}
                            placeholder="Enter Moisture Percentage"
                            min={0.00}
                            max={29.90}
                        />

                        <InputWithSideLabelAndFactor
                            label="Discount 1"
                            type='number'
                            value={tmpTicketObj.disc_amt1}
                            onChange={(e) => setTmpTicketData('disc_amt1', e.target.value, 'float')}
                            onBlur={(e) => handleOnBlur('disc_amt1')}
                            placeholder="Enter discount 1"
                            factor="00.0001" // TODO Change to dynamic
                        />

                        <InputWithSideLabelAndFactor
                            label="Discount 2"
                            type='number'
                            value={tmpTicketObj.disc_amt2}
                            onChange={(e) => setTmpTicketData('disc_amt2', e.target.value, 'float')}
                            onBlur={(e) => handleOnBlur('disc_amt2')}
                            placeholder="Enter discount 2"
                            factor="00.0001" // TODO Change to dynamic
                        />

                        <InputWithSideLabelAndFactor
                            label="Discount 3"
                            type='number'
                            value={tmpTicketObj.disc_amt3}
                            onChange={(e) => setTmpTicketData('disc_amt3', e.target.value, 'float')}
                            onBlur={(e) => handleOnBlur('disc_amt3')}
                            placeholder="Enter discount 3"
                            factor="00.0001" // TODO Change to dynamic
                        />

                    </div>
                </div>

                <div className='p-1'>
                    <div className="h-100  bg-light border border-dark border-1 p-3 rounded-2">
                        <h4 className='text-center'>Commodity Information</h4>

                        <SelectWithSideLabel
                            label="Crop Year"
                            value={tmpTicketObj.crop_year}
                            onChange={(e) => setTmpTicketData('crop_year', e.target.value, 'integer')}
                            onBlur={(e) => handleOnBlur('crop_year')}
                            options={['2021', '2022', '2023', '2024']}
                        />
                        <TextInputWithSideLabel
                            label="Grade"
                            value={tmpTicketObj.grade}
                            onChange={(e) => setTmpTicketData('grade', e.target.value, 'string')}
                            onBlur={(e) => handleOnBlur('grade')}
                            placeholder="Enter Grade"
                        />
                        <TextInputWithSideLabel
                            label="Reference"
                            value={tmpTicketObj.reference_number}
                            onChange={(e) => setTmpTicketData('reference_number', e.target.value, 'string')}
                            onBlur={(e) => handleOnBlur('reference_number')}
                            placeholder="Enter Reference"
                        />
                        <InputAreaWithSideLabel
                            label="Comments"
                            value={tmpTicketObj.comments}
                            onChange={(e) => setTmpTicketData('comments', e.target.value, 'string')}
                            onBlur={(e) => handleOnBlur('comments')}
                        />

                    </div>
                </div>

                <div className='p-1'>
                    <div className=" h-100 bg-light border border-dark border-1 p-3 rounded-2">
                        <h4 className='text-center'>Camera</h4>
                        <div>
                            <div className="d-flex justify-content-center align-items-center my-2">
                                <img src="https://via.placeholder.com/500x300" alt="ticket" style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                            <div className="d-flex justify-content-start align-items-center my-2">
                                <Button variant="success"><FontAwesomeIcon icon={faCamera} /> Capture</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>

        </div>
    );
};

export default NewTicket;
