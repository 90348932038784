import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const CreateCashbidModal = ({ show, onHide }) => {
    const [commodity, setCommodity] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [expiry, setExpiry] = useState('');
    const [location, setLocation] = useState('');
    const [cropYear, setCropYear] = useState('');
    const [basis, setBasis] = useState('');
    const [currency, setCurrency] = useState('CAD');
    const [unit, setUnit] = useState('');
    const [minVolume, setMinVolume] = useState('');
    const [maxVolume, setMaxVolume] = useState('');
    const [comments, setComments] = useState('');

    const apiLink = 'http://localhost:5000/cashbid';
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const cashbidData = {
            commodity,
            deliveryDate,
            expiry,
            location,
            cropYear,
            basis,
            unit,
            currency,
            minVolume,
            maxVolume,
            comments,
        };
        try {
            const response = await axios.post(apiLink, cashbidData);
            if (response.status === 201) {
                console.log('Cash bid created successfully');
            }
            window.location.reload();
        } catch (error) {
            console.error('Error creating cash bid:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create Cash Bid</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="commodity">
                                <Form.Label>Commodity</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={commodity}
                                    onChange={(e) => setCommodity(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="deliveryDate">
                                <Form.Label>Delivery</Form.Label>
                                <Form.Control
                                    type="month"
                                    value={deliveryDate}
                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="expiry">
                                <Form.Label>Expiry</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={expiry}
                                    onChange={(e) => setExpiry(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="location">
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    disabled
                                    type="text"
                                    value="Elevator"
                                    onChange={(e) => setLocation(e.target.value)}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="cropYear">
                                <Form.Label>Crop Year</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="1900"
                                    max="2100"
                                    step="1"
                                    onChange={(e) => setCropYear(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="basis">
                                <Form.Label>Basis</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={basis}
                                    onChange={(e) => setBasis(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="currency">
                                <Form.Label>Currency</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    required
                                >
                                    <option value="">Select Currency</option>
                                    <option value="CAD">CAD</option>
                                    <option value="USD">USD</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="unit">
                                <Form.Label>Unit</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value)}
                                    required
                                >
                                    <option value="">Select Unit</option>
                                    <option value="Bu">Bushels (Bu.)</option>
                                    <option value="Tn">Tonnes (T.)</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="minVolume">
                                <Form.Label>Minimum Volume</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={minVolume}
                                    onChange={(e) => setMinVolume(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="maxVolume">
                                <Form.Label>Maximum Volume</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={maxVolume}
                                    placeholder='Optional'
                                    onChange={(e) => setMaxVolume(e.target.value)}

                                />
                            </Form.Group>

                        </Col>
                    </Row>
                    <Form.Group controlId="comments" className="col-12">
                        <Form.Label>Comments</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={comments}
                            placeholder='Optional'
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" className='harvyst-green my-2' type="submit" onClick={handleSubmit}>
                        Create Cash Bid
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateCashbidModal;
